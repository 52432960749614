import React from 'react'

const Back = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#2F80ED" rx="20" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23 27.92l-6.52-6.52c-.77-.77-.77-2.03 0-2.8L23 12.08"
      />
    </svg>
  )
}

export default Back
