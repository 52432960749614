import React from 'react'
import {SVGProps} from './Dashboard'

const UserOctant: React.FC<SVGProps> = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        stroke={color || '#757575'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.08 8.58v6.84c0 1.12-.6 2.16-1.57 2.73l-5.94 3.43c-.97.56-2.17.56-3.15 0l-5.94-3.43a3.15 3.15 0 01-1.57-2.73V8.58c0-1.12.6-2.16 1.57-2.73l5.94-3.43c.97-.56 2.17-.56 3.15 0l5.94 3.43c.97.57 1.57 1.6 1.57 2.73z"
      />
      <path
        stroke={color || '#757575'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 11a2.33 2.33 0 100-4.66A2.33 2.33 0 0012 11zM16 16.66c0-1.8-1.79-3.26-4-3.26s-4 1.46-4 3.26"
      />
    </svg>
  )
}

export default UserOctant
