import React from 'react'

export interface SVGProps {
  color?: string
}
const Dashboard: React.FC<SVGProps> = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={color || '#757575'}
        stroke={`${color}` || '#757575'}
        strokeWidth="1.5"
        d="M16.77 2.75h1.9c.964 0 1.577.253 1.952.628s.628.988.628 1.952v1.9c0 .964-.253 1.577-.628 1.952s-.988.628-1.952.628h-1.9c-.964 0-1.577-.253-1.952-.628s-.628-.988-.628-1.952v-1.9c0-.964.253-1.577.628-1.952s.988-.628 1.952-.628zM9.81 16.767h0V18.67c0 .964-.253 1.577-.628 1.952s-.988.628-1.952.628h-1.9c-.964 0-1.577-.253-1.952-.629-.375-.375-.628-.991-.628-1.961v-1.9c0-.964.253-1.577.628-1.952.376-.375.992-.628 1.962-.628h1.9c.964 0 1.576.253 1.95.628.373.375.624.99.62 1.959zM9.83 6.29a3.54 3.54 0 11-7.08 0 3.54 3.54 0 017.08 0zM21.25 17.71a3.54 3.54 0 11-7.08 0 3.54 3.54 0 017.08 0z"
      />
    </svg>
  )
}

export default Dashboard
