import React from 'react'

const LeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
      <path
        stroke="#2F80ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M19 10L31 24l-12 14"
      />
    </svg>
  )
}

export default LeftIcon
