import React from 'react'

const DropDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke="#0C0407"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 6l4 4 4-4"
      />
    </svg>
  )
}

export default DropDownIcon
