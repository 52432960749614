import React from 'react'
import {useTranslation} from 'react-i18next'
import './pricingCard.css'
import BasicPlan from '../../atoms/illustrations/BasicPlan'
import Spacer from '../../atoms/spacer/Spacer'
import {PricingCardProps} from './pricingCard.props'

const PricingCard: React.FC<PricingCardProps> = ({
  features,
  planName,
  planType,
  price,
  isCurrentPlan,
  paymentLink,
}) => {
  const {t} = useTranslation()
  const handleButtonClick = (url: string) => {
    window.open(url, '_blank')
  }
  return (
    <div className="price-card">
      <div className="price-card-header d-flex align-items-center gap-3 mb-4">
        <BasicPlan />
        <h2 className="price-card-title mb-0">{planName}</h2>
      </div>
      <p className="desg-text mb-4">{planType}</p>
      <div className="price-disp-text text-center mb-1">
        <div className="price-text">
          <sub>$</sub>
          <span className="price">{price}</span> <sub className="sub-text">{t('perMonth')}</sub>
        </div>
        <Spacer size={16} />
      </div>

      <button
        className="plan-button btn btn-primary w-100 border-0"
        aria-label="select plan"
        type="button"
        style={{backgroundColor: isCurrentPlan ? '#56c200' : '#007aff'}}
        onClick={() => handleButtonClick(paymentLink)}
      >
        {isCurrentPlan ? t('currentPlan') : t('upgradePlan')}
      </button>
      <Spacer size={16} />
      <div dangerouslySetInnerHTML={{__html: features}} />
    </div>
  )
}

export default PricingCard
