import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import AddEmployeeForm from '../../organism/addEmployeeForm/AddEmployeeForm'
import {DateDataType, EmployeeValues} from '../../organism/addEmployeeForm/addEmployee.props'
import {useAppDispatch, useSelector} from '../../../store'
import {
  getEmployeeListAction,
  saveEmployeeAction,
  updateEmployeeAction,
} from '../../../store/action/employee'
import showNotification from '../../../function/notification'
import {RootState} from '../../../store/slices'

const AddEmployeeScreen = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const employeeData = useSelector((state: RootState) => state.employee.employeeList.employeeList)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<string | undefined>('')
  const handleAddEmployee = async (
    values: EmployeeValues,
    dateRanges: DateDataType[],
    startDate?: Date | null,
    endDate?: Date | null,
  ) => {
    try {
      const updatedAddedValues = [
        ...(startDate && endDate
          ? [
              {
                from_date_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                to_date_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
              },
            ]
          : []),
        ...(dateRanges?.map((item) => ({
          from_date_time: moment(item.startDate).format('YYYY-MM-DD HH:mm:ss'),
          to_date_time: moment(item.endDate).format('YYYY-MM-DD HH:mm:ss'),
        })) ?? []),
      ].filter(Boolean)
      setLoading(true)
      const response = employeeData?.[0]?.slug
        ? await dispatch(
            updateEmployeeAction({
              employee_slug: employeeData?.[0]?.slug,
              country_id: values.country.id,
              name: values.fullName,
              phone: values.phone,
              zipcode: values.zipCode,
              time_slot_minute: values.timeSlot,
              about: values.about,
              city: values.city,
              address: values.address,
              leaves: updatedAddedValues,
              email: values.email,
            }),
          ).unwrap()
        : await dispatch(
            saveEmployeeAction({
              country_id: values.country.id,
              name: values.fullName,
              phone: values.phone,
              zipcode: values.zipCode,
              time_slot_minute: values.timeSlot,
              about: values.about,
              city: values.city,
              address: values.address,
              leaves: dateRanges.map((item) => {
                return {
                  from_date_time: moment(item.startDate).format('YYYY-MM-DD HH:mm:ss'),
                  to_date_time: moment(item.endDate).format('YYYY-MM-DD HH:mm:ss'),
                }
              }),
              email: values.email,
              password: values.password,
            }),
          ).unwrap()
      showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
      await dispatch(getEmployeeListAction({page: 1}))
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }
  return (
    <AddEmployeeForm
      handleAddEmployee={(values, dateRanges, startDate, endDate) => {
        handleAddEmployee(values, dateRanges, startDate, endDate)
      }}
      loading={loading}
      setErrors={setErrors}
      errorText={errors}
    />
  )
}

export default AddEmployeeScreen
