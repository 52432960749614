import {AxiosPromise} from 'axios'
import {
  CountryListResponse,
  DashboardParams,
  DashboardResponse,
  PlanResponse,
} from '../store/action/types'
import Api from '../service/api'

export const getCountryList = (): AxiosPromise<CountryListResponse> => {
  return Api.get('/country-list')
}

export const getPlanList = (): AxiosPromise<PlanResponse> => {
  return Api.get('/plan-list')
}
export const getDashboardData = (body: DashboardParams): AxiosPromise<DashboardResponse> => {
  return Api.get('/dashboard', {params: body})
}
