import logException from '../service/sentry'
import showNotification from './notification'

export const apiErrorBlock = (e: any) => {
  logException(e)
  showNotification({
    title: 'Error',
    message: e?.response?.statusText || e?.message,
    type: 'danger',
  })
}
