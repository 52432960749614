import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {getCountryListAction, getDashboardDataAction, getPlanListAction} from '../action/general'
import {
  Country,
  CountryListResponse,
  DashboardResponse,
  PlanResponse,
  PlanState,
} from '../action/types'
import {formatCountryList, formatPlanList} from '../../function/formatApiResponse'
import {logoutUserAction} from '../action/auth'
import {DeactivateAccountAction} from '../action/profile'

export interface GeneralState {
  countryList: Country[]
  planList: PlanState[]
  dashboardAppointmentList: {
    agentsCount: string
    appointmentsCount: string
    employeesCount: string
    appointments: any
  }
}
const initialState: GeneralState = {
  countryList: [],
  planList: [],
  dashboardAppointmentList: {
    agentsCount: '',
    appointmentsCount: '',
    employeesCount: '',
    appointments: {},
  },
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCountryListAction.fulfilled,
      (state, action: PayloadAction<CountryListResponse>) => {
        state.countryList = formatCountryList(action.payload.data)
      },
    )
    builder.addCase(getPlanListAction.fulfilled, (state, action: PayloadAction<PlanResponse>) => {
      state.planList = formatPlanList(action.payload.data)
    })
    builder.addCase(
      getDashboardDataAction.fulfilled,
      (state, action: PayloadAction<DashboardResponse>) => {
        state.dashboardAppointmentList.agentsCount = action.payload.data.agents_count
        state.dashboardAppointmentList.appointmentsCount = action.payload.data.appointments_count
        state.dashboardAppointmentList.employeesCount = action.payload.data.employees_count
        state.dashboardAppointmentList.appointments = action.payload.data.appointments
      },
    )

    builder.addCase(logoutUserAction.fulfilled, (state) => {
      state.countryList = initialState.countryList
      state.planList = initialState.planList
      state.dashboardAppointmentList = initialState.dashboardAppointmentList
    })
    builder.addCase(DeactivateAccountAction.fulfilled, (state) => {
      state.countryList = initialState.countryList
      state.planList = initialState.planList
      state.dashboardAppointmentList = initialState.dashboardAppointmentList
    })
  },
})
