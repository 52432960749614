import {createAsyncThunk} from '@reduxjs/toolkit'
import {withToastForError} from '../../utils/redux/thunk'
import {UploadMediaParams} from '../../type/media'
import {ApiResponse} from '../../type/api/auth'
import {uploadMedia} from '../../api/media'

export const uploadMediaAction = createAsyncThunk(
  'media/uploadMedia',
  withToastForError<UploadMediaParams, ApiResponse>(async (data) => {
    const response = await uploadMedia(data)
    return response.data
  }),
)
