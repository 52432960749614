import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import AuthTemplate from '../../../template/authTemplate/AuthTemplate'
import {authInfoData} from '../../../../utils/constants/constant'
import background from '../../../../assets/images/background.png'
import './verificationSuccess.css'

const VerificationSuccessScreen = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  return (
    <AuthTemplate
      title={t('Congratulations')}
      description={t('emailVerified')}
      authInfo={authInfoData}
      image={background}
      noBack
      isVerification
      FormComponent={
        <button
          className="verified-btn border-0 w-100"
          type="button"
          aria-label={t('continue')}
          onClick={() => navigate('/login')}
        >
          <p className="verification-text m-0">{t('continue')}</p>
        </button>
      }
    />
  )
}

export default VerificationSuccessScreen
