import React from 'react'
import {SVGProps} from './Dashboard'

const Call: React.FC<SVGProps> = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <path
        fill={color || '#757575'}
        d="M13.861 12.89a.879.879 0 00-.627.25l-2.243 2.244c-2.798-.814-3.915-3.081-4.281-4.087l2.424-2.424c.288-.288.328-.69.119-1.196C8.77 6.514 5.636 2.11 3.866 2.11c-.316 0-.61.12-.826.336L.68 4.806C-.813 6.299.684 13.14 4.787 17.244c3.26 3.26 7.683 4.743 10.414 4.743.977 0 1.673-.2 2.067-.594l2.36-2.36c.17-.169.529-.647.205-1.428-.651-1.572-4.837-4.716-5.972-4.716zm1.34 7.546c-2.02.051-6.17-1.142-9.316-4.29-3.95-3.949-4.572-9.518-4.097-10.254L3.97 3.71c.34.175 2.749 2.103 3.76 4.372L5.415 10.4c-.29.29-.394.713-.272 1.105.319 1.022 1.673 4.435 5.717 5.451.087.022.422.326 1.044-.29l2.132-2.131c1.065.513 3.89 2.677 4.328 3.569 0 0-2.02 2.303-3.162 2.333zM19.942 0h-8.223C10.606 0 9.75.892 9.75 2.004v3.414c0 .428.322.775.75.775s.75-.347.75-.775V2.004c0-.256.212-.504.469-.504h8.223c.256 0 .558.248.558.504v13.344c0 .428.322.775.75.775s.75-.347.75-.776V2.005C22 .892 21.054 0 19.942 0z"
      />
      <path
        fill={color || '#757575'}
        d="M13.736 4.75h4.034c.428 0 .775-.447.775-.875 0-.429-.347-.875-.775-.875h-4.034c-.429 0-.776.446-.776.875 0 .428.347.875.776.875zM13.736 8h4.034c.428 0 .775-.322.775-.75s-.347-.75-.775-.75h-4.034c-.429 0-.776.322-.776.75s.347.75.776.75zM13.736 11.5h4.034c.428 0 .775-.322.775-.75s-.347-.75-.775-.75h-4.034c-.429 0-.776.322-.776.75s.347.75.776.75z"
      />
    </svg>
  )
}

export default Call
