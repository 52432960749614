import {AxiosPromise} from 'axios'
import Api from '../service/api'
import {
  AddAgentBody,
  AgentListBody,
  AgentListResponse,
  DeleteAgentBody,
  EditAgentBody,
} from '../type/api/agent'
import {ApiResponse} from '../type/api/auth'

export const addAgent = (body: AddAgentBody): AxiosPromise<ApiResponse> => {
  return Api.post('/save-agent', body)
}
export const deleteAgent = (body: DeleteAgentBody): AxiosPromise<ApiResponse> => {
  return Api.post('/delete-agent', body)
}

export const agentList = (body: AgentListBody): AxiosPromise<AgentListResponse> => {
  return Api.get('/agent-list', {params: body})
}

export const editAgent = (body: EditAgentBody): AxiosPromise<ApiResponse> => {
  return Api.post('/update-agent', body)
}
