import React from 'react'

const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g stroke="#BABABA" strokeWidth="2" clipPath="url(#clip0_818_6386)">
        <circle cx="10" cy="10.001" r="8.167" />
        <path d="M10 15V8.333M10 5v1.667" />
      </g>
      <defs>
        <clipPath id="clip0_818_6386">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Info
