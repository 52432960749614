import React from 'react'
import {SVGProps} from './Dashboard'

const Agent: React.FC<SVGProps> = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={color || '#757575'}
        d="M19.938 8H21a2 2 0 012 2v4a2 2 0 01-2 2h-1.062A8 8 0 0112 23v-2a6 6 0 006-6V9A6 6 0 106 9v7H3a2 2 0 01-2-2v-4a2 2 0 012-2h1.062a8.001 8.001 0 0115.876 0zM3 10v4h1v-4H3zm17 0v4h1v-4h-1zM7.76 15.785l1.06-1.696a5.973 5.973 0 003.18.91 5.973 5.973 0 003.18-.91l1.06 1.696A7.963 7.963 0 0112 17a7.964 7.964 0 01-4.24-1.215z"
      />
    </svg>
  )
}

export default Agent
