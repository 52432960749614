import React, {useEffect, useMemo, useState} from 'react'
import {Form, Button, Dropdown} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import '../loginForm/loginForm.css'
import Spacer from '../../atoms/spacer/Spacer'
import './signUpForm.css'
import '../../template/authTemplate/authTemplate.css'
import EyeOpen from '../../atoms/illustrations/EyeOpen'
import {SignUpFormProps} from './signUpForm.props'
import {getCountryListAction} from '../../../store/action/general'
import {useAppDispatch, useSelector} from '../../../store'
import {RootState} from '../../../store/slices'
import LoadingButton from '../../atoms/button/Button'
import {signUpValidationSchema} from '../../../utils/validations/validation'
import EyeClose from '../../atoms/illustrations/EyeClose'

const SignUpForm: React.FC<SignUpFormProps> = ({handleSignUp, loading}) => {
  const {t} = useTranslation()
  const countryList = useSelector((state: RootState) => state.general.countryList)
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [diallingCodeList, setDiallingCodeList] = useState(countryList)
  const initialValues = {
    fullName: '',
    email: '',
    phoneNumber: '',
    zipCode: '',
    password: '',
    confirmPassword: '',
    countryCodeAlpha: countryList?.[0],
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const getCountryList = async () => {
    if (countryList.length <= 0) {
      await dispatch(getCountryListAction()).unwrap()
    }
  }
  useEffect(() => {
    getCountryList()
  }, [])

  useEffect(() => {
    setDiallingCodeList(countryList)
  }, [countryList])
  const combinedSignUpValidationSchema = useMemo(() => {
    return Yup.object().shape({
      ...signUpValidationSchema,
      zipCode: Yup.string()
        .matches(initialValues?.countryCodeAlpha?.expression, t('invalid'))
        .required(t('required')),
    })
  }, [initialValues.countryCodeAlpha, t])
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={combinedSignUpValidationSchema}
      onSubmit={(values, {setSubmitting}) => {
        if (handleSignUp) {
          handleSignUp(values)
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
      }) => {
        const isFormValid =
          Boolean(values.fullName) &&
          Boolean(values.email) &&
          Boolean(values.phoneNumber) &&
          Boolean(values.zipCode) &&
          Boolean(values.password) &&
          Boolean(values.confirmPassword) &&
          isValid
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="form-container">
              <Form.Group controlId="email">
                <p className="form-labels pb-1 m-0">{t('email')}</p>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder={`${t('emailEg')}`}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!(touched.email && errors.email)}
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  {`${t('email')} ${t(errors?.email || '')}`}
                </Form.Control.Feedback>
              </Form.Group>
              <Spacer size={8} />
              <Form.Group controlId="fullName">
                <p className="form-labels pb-1 m-0">{t('fullName')}</p>
                <Form.Control
                  type="text"
                  name="fullName"
                  placeholder={`${t('fullNameEg')}`}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!(touched.fullName && errors.fullName)}
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  {`${t('fullName')} ${t(errors?.fullName || '')}`}
                </Form.Control.Feedback>
              </Form.Group>

              <Spacer size={8} />
              <Form.Group controlId="phoneNumber">
                <div className="d-flex flex-row justify-content-between align-items-center gap-1">
                  <Dropdown>
                    <p className="form-labels pb-1 m-0">{t('countryCode')}</p>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="custom-input border-0 gap-2 d-flex pb-0 mb-0 align-items-center"
                    >
                      <img
                        src={values.countryCodeAlpha?.flag}
                        alt={`"flag"`}
                        height={20}
                        width={20}
                      />
                      <p className="p-0 m-0 text-dark"> +{values.countryCodeAlpha?.dialCode} </p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {diallingCodeList.map(
                        (
                          option: {
                            id: any
                            flag: string | undefined
                            dialCode: string
                          },
                          index: number,
                        ) => (
                          <Dropdown.Item
                            key={`${option.id} - ${index + 1}`}
                            onClick={() => setFieldValue('countryCodeAlpha', option)}
                            className="description-text"
                          >
                            <div className="d-flex align-items-center gap-3">
                              <img src={option.flag} alt={`"flag"`} height={20} width={20} />
                              <p className="p-0 m-0 "> +{option.dialCode} </p>
                            </div>
                          </Dropdown.Item>
                        ),
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="flex-grow-1">
                    <p className="form-labels pb-1 m-0">{t('phoneNumber')}</p>
                    <Form.Control
                      type="tel"
                      name="phoneNumber"
                      placeholder={`${t('phoneNumberEg')}`}
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '')
                      }}
                      isInvalid={!!(touched.phoneNumber && errors.phoneNumber)}
                      className="custom-input"
                    />
                  </div>
                </div>
                {touched.phoneNumber && errors?.phoneNumber && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {`${t('phoneNumber')} ${t(errors?.phoneNumber || '')}`}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Spacer size={8} />
              <Form.Group controlId="zipCode">
                <p className="form-labels pb-1 m-0">
                  {values.countryCodeAlpha?.countryCodeAlpha === 'CA'
                    ? t('postalCode')
                    : t('zipCode')}
                </p>
                <Form.Control
                  type="text"
                  name="zipCode"
                  placeholder={`${
                    values.countryCodeAlpha?.countryCodeAlpha === 'CA'
                      ? t('postalCodeEg')
                      : t('zipCodeEg')
                  }`}
                  value={values.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!(touched.zipCode && errors.zipCode)}
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  {`${
                    values.countryCodeAlpha?.countryCodeAlpha === 'CA'
                      ? t('postalCode')
                      : t('zipCode')
                  } ${t(errors?.zipCode || '')}`}
                </Form.Control.Feedback>
              </Form.Group>
              <Spacer size={8} />
              <Form.Group controlId="password">
                <p className="form-labels pb-1 m-0">Password</p>
                <div className="password-field-input">
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder={`${t('password')}`}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!(touched.password && errors.password)}
                    className="custom-input"
                  />
                  <Button
                    variant="link"
                    onClick={togglePasswordVisibility}
                    className={`eye-button ${
                      errors.password && touched.password ? 'error-occurred' : ''
                    }`}
                    aria-label={showPassword ? t('hidePassword') : t('showPassword')}
                  >
                    {showPassword ? <EyeOpen /> : <EyeClose />}
                  </Button>

                  <Form.Control.Feedback type="invalid">
                    {`${t('password')} ${t(errors?.password || '')}`}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Spacer size={8} />
              <Form.Group controlId="confirmPassword">
                <p className="form-labels pb-1 m-0">{t('confirmPassword')}</p>
                <div className="password-field-input">
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder={`${t('confirmPassword')}`}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!(touched.confirmPassword && errors.confirmPassword)}
                    className="custom-input"
                  />

                  <Button
                    variant="link"
                    onClick={toggleConfirmPasswordVisibility}
                    className={`eye-button ${
                      errors.confirmPassword && touched.confirmPassword ? 'error-occurred' : ''
                    }`}
                    aria-label={showConfirmPassword ? t('hidePassword') : t('showPassword')}
                  >
                    {showConfirmPassword ? <EyeOpen /> : <EyeClose />}
                  </Button>

                  <Form.Control.Feedback type="invalid">
                    {`${t('confirmPassword')} ${t(errors?.confirmPassword || '')}`}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>

            <LoadingButton
              buttonText={t('createAccount')}
              isSubmitting={loading}
              isValid={isFormValid}
            />
            <Spacer size={24} />
            <div className="register-container">
              <p className="register-text">
                {t('signInWithUs')} <br />
                <a className="forgot-text">{t('termsCondition')}</a>
              </p>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SignUpForm
