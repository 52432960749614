import React from 'react'
import {useTranslation} from 'react-i18next'
import AuthTemplate from '../../template/authTemplate/AuthTemplate'
import {authInfoData} from '../../../utils/constants/constant'
import background from '../../../assets/images/background.png'

const CheckEmailScreen = () => {
  const {t} = useTranslation()
  return (
    <AuthTemplate
      title={t('checkEmail')}
      description={t('checkEmailDesc')}
      authInfo={authInfoData}
      image={background}
      noBack
      isVerification
    />
  )
}

export default CheckEmailScreen
