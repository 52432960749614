/* eslint-disable import/no-extraneous-dependencies */
import {combineReducers} from 'redux'
import storage from 'redux-persist/lib/storage'
import {PersistConfig} from 'redux-persist'
import {userSlice} from './user'
import {authSlice} from './auth'
import {generalSlice} from './general'
import {agentSlice} from './agent'
import {appointmentSlice} from './appointment'
import {profileSlice} from './profile'
import {employeeSlice} from './employee'

export const rootReducer = combineReducers({
  user: userSlice.reducer,
  auth: authSlice.reducer,
  general: generalSlice.reducer,
  agent: agentSlice.reducer,
  appointment: appointmentSlice.reducer,
  profile: profileSlice.reducer,
  employee: employeeSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'auth', 'general', 'agent', 'profile', 'appointment', 'employee'],
} as PersistConfig<RootState>

export const userActions = userSlice.actions
export const authActions = authSlice.actions
export const generalActions = generalSlice.actions
