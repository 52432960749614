import React from 'react'

const Remove = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="50" fill="none" viewBox="0 0 32 50">
      <rect width="32" height="32" y="9" fill="#FFEBEB" rx="16" />
      <path fill="#D13E3E" d="M9 24v2h14v-2H9z" />
    </svg>
  )
}

export default Remove
