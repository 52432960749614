import React from 'react'

interface AddButtonProps {
  height?: number
  width?: number
}

const AddButton: React.FC<AddButtonProps> = ({height, width}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '32'}
      height={height || '32'}
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width={width || '32'} height={width || '32'} fill="#2F80ED" rx="16" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 16h14M16 23V9"
      />
    </svg>
  )
}

export default AddButton
