import React from 'react'
import './helpCard.css'
import VideoCall from '../../atoms/illustrations/VideoCall'
import {HelpCardProps} from './helpCard.props'

const HelpCard: React.FC<HelpCardProps> = ({title, image}) => {
  return (
    <div
      className="help-card-container d-flex flex-column justify-content-between align-items-start"
      style={{backgroundImage: `url(${image})`}}
    >
      <VideoCall />
      <p className="help-description m-0">{title}</p>
    </div>
  )
}

export default HelpCard
