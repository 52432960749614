import React from 'react'
import './comingSoon.css'

const ComingSoonScreen = () => {
  return (
    <div className="container">
      <p className="text m-0">Coming soon...</p>
    </div>
  )
}

export default ComingSoonScreen
