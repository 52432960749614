import {createAsyncThunk} from '@reduxjs/toolkit'
import {withToastForError} from '../../utils/redux/thunk'
import {
  AddAgentBody,
  AgentListBody,
  AgentListResponse,
  DeleteAgentBody,
  EditAgentBody,
} from '../../type/api/agent'
import {ApiResponse} from '../../type/api/auth'
import {addAgent, agentList, deleteAgent, editAgent} from '../../api/agent'

export const addAgentAction = createAsyncThunk(
  'agent/addAgent',
  withToastForError<AddAgentBody, ApiResponse>(async (data) => {
    const response = await addAgent(data)
    return response.data
  }),
)

export const deleteAgentAction = createAsyncThunk(
  'agent/deleteAgent',
  withToastForError<DeleteAgentBody, ApiResponse>(async (data) => {
    const response = await deleteAgent(data)
    return response.data
  }),
)

export const agentListAction = createAsyncThunk(
  'agent/agentList',
  withToastForError<AgentListBody, AgentListResponse>(async (data) => {
    const response = await agentList(data)
    return response.data
  }),
)

export const editAgentAction = createAsyncThunk(
  'agent/editAgent',
  withToastForError<EditAgentBody, ApiResponse>(async (data) => {
    const response = await editAgent(data)
    return response.data
  }),
)
