import React, {useEffect, useState} from 'react'
import '../../organism/ediAgentDetailForm/editAgentDetailForm.css'
import {Modal, Spinner} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useAppDispatch} from '../../../store'
import EditProfileForm from '../../organism/editProfileForm/EditProfileForm'
import './editProfileScreen.css'
import Spacer from '../../atoms/spacer/Spacer'
import {EditProfileFormValues} from '../../organism/editProfileForm/editProfileForm.props'
import ProfileDelete from '../../atoms/illustrations/ProfileDelete'
import {
  DeactivateAccountAction,
  getProfileDataAction,
  updateProfileAction,
} from '../../../store/action/profile'
import showNotification from '../../../function/notification'
import {MediaUserType} from '../../../type/media'
import {uploadMediaAction} from '../../../store/action/media'

const EditProfileScreen = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [deactivateModal, setDeactivateModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deactivateLoading, setDeactivateLoading] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const handleEdit = async (values: EditProfileFormValues) => {
    try {
      setLoading(true)
      const response = await dispatch(
        updateProfileAction({
          address: values.address,
          phone: values.phoneNumber,
          zipcode: values.postalCode,
          city: values.city,
          email: values.email,
          name: values.firstName,
        }),
      ).unwrap()
      showNotification({
        title: t('success'),
        type: 'success',
        message: `${response?.message || t('profileUpdated')}`,
      })
      navigate('/dashboard')
      await getProfileData()
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }
  const handleClose = () => {
    setDeactivateModal(false)
  }
  const handleDeactivateAccount = async () => {
    try {
      setDeactivateLoading(true)
      const response = await dispatch(DeactivateAccountAction()).unwrap()
      showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setDeactivateLoading(false)
    setDeactivateModal(false)
    navigate('/login')
  }
  const getProfileData = async () => {
    try {
      await dispatch(getProfileDataAction())
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getProfileData()
  }, [])

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0]

    try {
      if (file) {
        setFileLoading(true)
        const formData: any = new FormData()
        formData.append('media', file)
        formData.append('type', MediaUserType.PROFILEIMAGE)
        const response = await dispatch(uploadMediaAction(formData)).unwrap()
        showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
        await getProfileData()
      }
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setFileLoading(false)
  }

  return (
    <>
      <div className="edit-profile-screen-view">
        <div className="d-flex flex-row justify-content-between align-items-center ps-4 pe-4">
          <p className="edit-profile-text m-0">{t('editProfile')}</p>
          <button
            type="button"
            className="remove-btn border-0 rounded-5"
            onClick={() => setDeactivateModal(true)}
          >
            <p className="p-0 m-0 remove-btn-text"> {t('deactivateAccount')}</p>
          </button>
        </div>
        <Spacer size={12} />
        <EditProfileForm
          handleEditForm={(values) => handleEdit(values)}
          loading={loading}
          handleFileChange={handleFileChange}
          fileLoading={fileLoading}
        />
      </div>
      <Modal show={deactivateModal} onHide={handleClose} centered>
        <div className="p-5 d-flex flex-column">
          <p className="modal-heading m-0">{t('areYouSure')}</p>
          <p className="modal-description">{t('deactivateDesc')}</p>
          <button
            type="button"
            className="remove-btn border-0 rounded-3 p-3"
            onClick={handleDeactivateAccount}
          >
            <div className="d-flex flex-row justify-content-between align-items-center">
              {deactivateLoading ? (
                <Spinner animation="border" size="sm" role="status" style={{color: 'white'}} />
              ) : (
                <>
                  <ProfileDelete />
                  <p className="p-0 m-0 remove-btn-text w-100"> {t('deactivateAccount')}</p>
                </>
              )}
            </div>
          </button>
          <Spacer size={8} />
          <button type="submit" className="next-btn  rounded-3 p-3" onClick={handleClose}>
            <p className="p-0 m-0 next-btn-text"> {t('goBack')}</p>
          </button>
        </div>
      </Modal>
    </>
  )
}

export default EditProfileScreen
