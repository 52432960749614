import {createAsyncThunk} from '@reduxjs/toolkit'
import {withToastForError} from '../../utils/redux/thunk'
import {getCountryList, getDashboardData, getPlanList} from '../../api/general'
import {CountryListResponse, DashboardParams, DashboardResponse, PlanResponse} from './types'

export const getCountryListAction = createAsyncThunk<CountryListResponse, void>(
  'public/countryList',
  withToastForError(async () => {
    const response = await getCountryList()
    return response.data
  }),
)
export const getPlanListAction = createAsyncThunk<PlanResponse, void>(
  'public/planList',
  withToastForError(async () => {
    const response = await getPlanList()
    return response.data
  }),
)

export const getDashboardDataAction = createAsyncThunk<DashboardResponse, DashboardParams>(
  'public/dashboardData',
  withToastForError(async (body) => {
    const response = await getDashboardData(body)
    return response.data
  }),
)
