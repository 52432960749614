import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import AddAgentForm from '../../organism/addAgentForm/AddAgentForm'
import {
  AddAgentFormValueFormValuesType,
  AddAgentFormValueTypes,
  Guideline,
  MiscellaneousType,
  QualifyQuestions,
  RulesData,
} from '../../organism/addAgentForm/addAgentForm.props'
import {useAppDispatch} from '../../../store'
import {addAgentAction, editAgentAction} from '../../../store/action/agent'
import {GuidelineType} from '../../../type/api/agent'
import showNotification from '../../../function/notification'

const AddAgentScreen = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  const handleAddAgent = async (
    values: any,
    addedValues: Guideline[],
    miscellaneousData: MiscellaneousType[],
    rulesData: RulesData[],
    questionData: QualifyQuestions[],
  ) => {
    try {
      setLoading(true)
      const updatedAddedValues = [
        ...(values.qualifyGuidelineKey !== '' && values.qualifyGuideline !== ''
          ? [
              {
                guideline_type: GuidelineType.Guidelines,
                identifier_key: values.qualifyGuidelineKey,
                identifier_value: values.qualifyGuideline,
              },
            ]
          : []),
        ...(addedValues?.map((item) => ({
          guideline_type: GuidelineType.Guidelines,
          identifier_key: item.qualifyGuidelineKey,
          identifier_value: item.qualifyGuideline,
        })) ?? []),
      ].filter(Boolean)

      const updatedMiscellaneousData = [
        ...(values.miscellaneousKey !== '' && values.miscellaneous !== ''
          ? [
              {
                guideline_type: GuidelineType.Miscellaneous,
                identifier_key: values.miscellaneousKey,
                identifier_value: values.miscellaneous,
              },
            ]
          : []),
        ...(miscellaneousData?.map((item) => ({
          guideline_type: GuidelineType.Miscellaneous,
          identifier_key: item.miscellaneousKey,
          identifier_value: item.miscellaneous,
        })) ?? []),
      ].filter(Boolean)

      const updatedRulesData = [
        ...(values.rulesKey !== '' && values.rules !== ''
          ? [
              {
                guideline_type: GuidelineType.RULES,
                identifier_key: values.rulesKey,
                identifier_value: values.rules,
              },
            ]
          : []),
        ...(rulesData?.map((item) => ({
          guideline_type: GuidelineType.RULES,
          identifier_key: item.rulesKey,
          identifier_value: item.rules,
        })) ?? []),
      ].filter(Boolean)

      const updatedQuestionData = [
        ...(values.questionGuidelineKey !== '' && values.questionGuideline !== ''
          ? [
              {
                guideline_type: GuidelineType.Faq,
                identifier_key: values.questionGuidelineKey,
                identifier_value: values.questionGuideline,
              },
            ]
          : []),
        ...(questionData?.map((item) => ({
          guideline_type: GuidelineType.Faq,
          identifier_key: item.questionGuidelineKey,
          identifier_value: item.questionGuideline,
        })) ?? []),
      ].filter(Boolean)

      const guideLines = [
        ...updatedAddedValues,
        ...updatedMiscellaneousData,
        ...updatedRulesData,
        ...updatedQuestionData,
      ]
      const response = id
        ? await dispatch(
            editAgentAction({
              agent_slug: id,
              name: values.agentName,
              slug: values.organizationName,
              greeting_message: values.greetingMessage,
              personality: values.personality,
              agenda: values.agenda,
              voicemail_message: values.voicemailMessage,
              about: values.companyInfo,
              guidelines: guideLines,
            }),
          ).unwrap()
        : await dispatch(
            addAgentAction({
              name: values.agentName,
              slug: values.organizationName,
              greeting_message: values.greetingMessage,
              personality: values.personality,
              agenda: values.agenda,
              voicemail_message: values.voicemailMessage,
              about: values.companyInfo,
              guidelines: guideLines,
            }),
          ).unwrap()
      navigate('/agent-settings')
      showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }
  return (
    <div>
      <AddAgentForm
        handleAddAgent={(
          values: AddAgentFormValueFormValuesType,
          addedValues: Guideline[],
          miscellaneousData: MiscellaneousType[],
          rulesData: RulesData[],
          questionData: QualifyQuestions[],
        ) => {
          handleAddAgent(values, addedValues, miscellaneousData, rulesData, questionData)
        }}
        loading={loading}
      />
    </div>
  )
}

export default AddAgentScreen
