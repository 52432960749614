import {Appointment} from '../../type/api/appointment'

export const setAppointmentData = (apiResponse: any): any[] => {
  const daysData: any[] = Array.from({length: 31}, (_, index) => ({
    day: index + 1,
    placeholders: [],
  }))
  for (const day in apiResponse) {
    if (Object.prototype.hasOwnProperty.call(apiResponse, day)) {
      const dayNumber = parseInt(day, 10)
      const appointments = apiResponse[dayNumber]

      daysData[dayNumber - 1] = {
        day: dayNumber,
        placeholders: appointments?.map((item: Appointment) => {
          return {
            appointment: item.appointment,
            cancelAt: item.cancel_at,
            cancelNote: item.cancel_note,
            createdAt: item.created_at,
            day: item.day,
            deletedAt: item.deleted_at,
            employeeId: item.employee_id,
            id: item.id,
            note: item.note,
            organizationId: item.organization_id,
            updatedAt: item.updated_at,
          }
        }),
      }
    }
  }

  return daysData
}
