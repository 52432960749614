import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Nav from 'react-bootstrap/Nav'
import {Button, Modal, Offcanvas} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import {useNavigate} from 'react-router-dom'
import InputGroup from 'react-bootstrap/InputGroup'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import {useAppDispatch, useSelector} from '../../../store'
import './appTemplate.css'
import More from '../../atoms/illustrations/More'
import Search from '../../atoms/illustrations/Search'
import {AppTemplateProps} from './appTemplate.props'
import DashboardCanvas from '../../molecules/dashboardCanvas/DashboardCanvas'
import {logoutUserAction} from '../../../store/action/auth'
import DropDownIcon from '../../atoms/illustrations/DropDownIcon'
import {RootState} from '../../../store/slices'
import {notificationData} from '../../../utils/constants/constant'
import Spacer from '../../atoms/spacer/Spacer'

const AppTemplate: React.FC<AppTemplateProps> = ({children}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.auth.user)
  const profileData = useSelector((state: RootState) => state.profile.profileInfo)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [active, setActive] = useState(t(''))
  const [dropDownValue, setDropDownValue] = useState(user?.name)
  const [isLarge, setIsLarge] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const handleClose = () => {
    setShow(false)
    setShowNotification(false)
  }
  useEffect(() => {
    setDropDownValue(profileData?.name)
  }, [profileData])
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setShow(true)
        setIsLarge(true)
      } else {
        setShow(false)
        setIsLarge(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleLogOut = async () => {
    await dispatch(logoutUserAction({token: user.token}))
    navigate('/login')
  }
  return (
    <div className="d-flex flex-row justify-content-between align-items-start w-100  flex-1 gap-3">
      {show && !isLarge && (
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Body className="d-flex flex-column justify-content-between align-items-start">
            <DashboardCanvas active={active} setActive={setActive} handleClose={handleClose} />
          </Offcanvas.Body>
        </Offcanvas>
      )}

      {isLarge && (
        <div className="d-flex flex-column justify-content-between align-items-start left-header-view">
          <DashboardCanvas active={active} setActive={setActive} handleClose={handleClose} />
        </div>
      )}
      <div className="main-class-view">
        <div className="d-flex flex-row justify-content-between align-items-center">
          {!show && !isLarge && (
            <Button onClick={handleShow} className="bg-transparent mt-1 border-0 mb-auto">
              <More />
            </Button>
          )}
          <Navbar expand="lg" className="w-100 justify-content-end">
            <Container fluid className="justify-content-end">
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="w-lg-auto justify-content-end"
              />
              <Navbar.Collapse id="basic-navbar-nav" className="w-lg-auto">
                <Nav>
                  <Form className="w-100 m-auto">
                    {/* <InputGroup className="align-items-center">
                      <Search />
                      <Form.Control
                        placeholder={t('searchAnything')}
                        aria-label={t('fullName')}
                        aria-describedby="basic-addon1"
                        className="border-0 ps-2 m-0 shadow-none search-input"
                      />
                    </InputGroup> */}
                  </Form>
                  <div className="d-flex flex-row gap-1 justify-content-around align-items-center">
                    <div className="d-flex flex-row justify-content-between align-items-center profile-view gap-3 p-2">
                      <img
                        src={
                          profileData?.profileImage ||
                          user?.profileImage ||
                          'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                        }
                        alt="profile"
                        className="profile-image"
                      />
                      <NavDropdown
                        title={
                          <span>
                            {dropDownValue} <DropDownIcon />
                          </span>
                        }
                        id="basic-nav-dropdown"
                      >
                        <NavDropdown.Item
                          onClick={() => navigate('/edit-profile')}
                          className="nav-bar-dropdown-class"
                        >
                          {t('editProfile')}
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleLogOut} className="nav-bar-dropdown-class">
                          {t('logout')}
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <Modal show={showNotification} onHide={handleClose} className="notification-modal">
          <div className="p-3">
            <div className="notification-card">
              <p className="notification-text m-0">{t('notification')}</p>
            </div>
            {notificationData.map((item, index) => (
              <div className="notification-card" key={`${item.title} - ${index + 2}`}>
                <p className="notification-title m-0">{item.title}</p>
                <Spacer size={12} />
                <p className="notification-desc m-0 ">{item.time}</p>
              </div>
            ))}
          </div>
        </Modal>
        <div className="main-class h-lg-100 h-sm-100 h-md-100">{children}</div>
      </div>
    </div>
  )
}

export default AppTemplate
