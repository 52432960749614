import React from 'react'
import {useTranslation} from 'react-i18next'
import {Form, Container} from 'react-bootstrap'
import {Formik} from 'formik'
import {AgentAppointmentFormFieldsSchema} from '../../../utils/validations/validation'
import Spacer from '../../atoms/spacer/Spacer'
import LoadingButton from '../../atoms/button/Button'
import './agentAppoinemtnSettingsForm.css'
import {appointmentSettingsOptions} from '../../../utils/constants/constant'

const AgentAppointmentSettingForm = () => {
  const {t} = useTranslation()
  const initialValues = {
    slotAvailability: '',
    slotsSeparator: '',
    slotsError: '',
    noSlotsAvailable: '',
    errorBookingAppointment: '',
    appointmentBooked: '',
    slotIndex: '',
  }

  const FormGroupWithClear = ({
    label,
    name,
    value,
    onChange,
    errors,
    touched,
    placeholder,
    dropdownOptions,
  }: any) => (
    <Form.Group className="w-100">
      <div className="password-field-input">
        <p className="form-labels pb-1 m-0">{t(label)}</p>

        <Form.Select
          name={name}
          value={value || ''}
          onChange={onChange}
          isInvalid={!!errors[name] && touched[name]}
          className="custom-input"
        >
          <option value="" className="placeholder-option">
            {placeholder}
          </option>
          {dropdownOptions.map((option: string, index: number) => (
            <option key={`${label} - ${index + 1}`} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      </div>
      <Form.Control.Feedback type="invalid">
        {`${t(label)} ${t(errors[name] || '')}`}
      </Form.Control.Feedback>
    </Form.Group>
  )

  return (
    <Container fluid className="pt-2 pb-2">
      <div className="add-agent-form-view add-agent-form">
        <p className="call-setting-text  pt-3 m-0">{t('appointmentSettings')}</p>
        <p className="description-text  pt-2 m-0">{t('appointmentSettingsDesc')}</p>
        <Spacer size={12} />
        <div className="agent-settings-btn-line" />
        <Spacer size={24} />
        <Formik
          initialValues={initialValues}
          validationSchema={AgentAppointmentFormFieldsSchema}
          onSubmit={(values, {setSubmitting}) => {
            console.log(values)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="agent-appointment-settings-form">
              <div className="d-flex flex-column justify-content-between align-items-center h-100">
                <div className="w-100">
                  <div className="add-agent-forms-view d-flex flex-row justify-content-center align-items-center gap-2">
                    <div className="w-100">
                      <FormGroupWithClear
                        label="slotAvailability"
                        name="slotAvailability"
                        value={values.slotAvailability}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        dropdownOptions={appointmentSettingsOptions.slotAvailability}
                        placeholder={t('select')}
                      />
                    </div>

                    <div className="w-100">
                      <FormGroupWithClear
                        label="slotsSeparator"
                        name="slotsSeparator"
                        value={values.slotsSeparator}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        dropdownOptions={appointmentSettingsOptions.slotsSeparator}
                        placeholder={t('select')}
                      />
                    </div>
                  </div>
                  <Spacer size={16} />
                  <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                    <FormGroupWithClear
                      label="slotsError"
                      name="slotsError"
                      value={values.slotsError}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      dropdownOptions={appointmentSettingsOptions.slotsError}
                      placeholder={t('slotErrorEg')}
                    />
                    <FormGroupWithClear
                      label="noSlotsAvailable"
                      name="noSlotsAvailable"
                      value={values.noSlotsAvailable}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      dropdownOptions={appointmentSettingsOptions.noSlotsAvailable}
                      placeholder={t('noSlotsAvailableEg')}
                    />
                  </div>
                  <Spacer size={16} />
                  <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                    <FormGroupWithClear
                      label="errorBookingAppointment"
                      name="errorBookingAppointment"
                      value={values.errorBookingAppointment}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      dropdownOptions={appointmentSettingsOptions.errorBookingAppointment}
                      placeholder={t('slotErrorEg')}
                    />
                    <FormGroupWithClear
                      label="appointmentBooked"
                      name="appointmentBooked"
                      value={values.appointmentBooked}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      dropdownOptions={appointmentSettingsOptions.appointmentBooked}
                      placeholder={t('enterSuccessMsg')}
                    />
                  </div>
                  <Spacer size={16} />
                  <div className="w-100">
                    <FormGroupWithClear
                      label="slotIndex"
                      name="slotIndex"
                      value={values.slotIndex}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      dropdownOptions={appointmentSettingsOptions.slotIndex}
                      placeholder={t('select')}
                    />
                  </div>
                  <Spacer size={16} />
                </div>

                <div className="save-btn-view d-flex justify-content-center align-items-center w-100">
                  <LoadingButton
                    buttonText={t('save')}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isCurvedButton
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

export default AgentAppointmentSettingForm
