import React from 'react'
import {AgentCardProps} from './agentCard.props'
import Message from '../../../atoms/illustrations/Message'
import Edit from '../../../atoms/illustrations/Edit'
import Trash from '../../../atoms/illustrations/Trash'
import './agentCard.css'
import User from '../../../atoms/illustrations/User'

const AgentCard: React.FC<AgentCardProps> = ({title, description, setModal, handleDeleteAgent}) => {
  const buttons = [
    {icon: <Message />, onClick: () => console.log('message clicked')},
    {icon: <Edit />, onClick: () => setModal(true)},
    {icon: <Trash />, onClick: handleDeleteAgent},
  ]
  return (
    <div className="agent-card-view  justify-content-between align-items-center p-3">
      <div className="agent-info-view d-flex flex-row justify-content-between align-items-center pt-2 pb-2 pe-3 gap-3">
        <User />
        <div>
          <p className="agent-title  p-0 m-0">{title}</p>
          <p className="agent-desc p-0 m-0">
            {description?.length > 50 ? `${description?.substring(0, 80)}...` : description}
          </p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center gap-4 pe-3">
        {buttons.map((button, index) => (
          <button
            type="button"
            className="border-0 bg-transparent"
            key={`${button.icon} - ${index + 1}`}
            onClick={button.onClick}
          >
            {button.icon}
          </button>
        ))}
      </div>
    </div>
  )
}

export default AgentCard
