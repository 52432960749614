import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import PricingCard from '../../molecules/pricingCard/PricingCard'
import './pricingScreen.css'
import {useAppDispatch, useSelector} from '../../../store'
import {getPlanListAction} from '../../../store/action/general'
import {RootState} from '../../../store/slices'
import Loader from '../../atoms/Loader'
import showNotification from '../../../function/notification'

const PricingScreen = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const planList = useSelector((state: RootState) => state.general.planList)
  const getPlanList = async () => {
    try {
      setLoading(true)
      await dispatch(getPlanListAction())
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }
  useEffect(() => {
    getPlanList()
  }, [])

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="pricing-screen ms-3 me-3 pt-5 pb-5">
          <div className="pricing-card-view d-flex flex-row justify-content-start align-items-start gap-3 ">
            {planList?.length > 0 &&
              planList?.map((item, index) => (
                <PricingCard
                  key={`price-card-${index + 1}`}
                  features={item?.description}
                  planName={item?.name}
                  planType={t('cancelAnyTime')}
                  price={item?.amount}
                  isCurrentPlan={false}
                  paymentLink={item?.paymentLink}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default PricingScreen
