import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {Button, Form} from 'react-bootstrap'
import {useAppDispatch, useSelector} from '../../store'
import {onUserLogin} from '../../store/action'
import {RootState} from '../../store/slices'
import {logoutUserAction} from '../../store/action/auth'

const Home = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const userData = useSelector((state: RootState) => state.auth.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (!userData?.token) {
      navigate('/login')
    } else {
      navigate('/dashboard')
    }
    const setTextUser = async () => {
      // TODO: will remove once we add original data
      const user = {
        id: userData.id,
        name: userData.name,
        email: userData.email,
        token: userData.token,
      }
      await dispatch(onUserLogin(user))
    }
    setTextUser()
  }, [])

  const handleSubmit = async () => {
    await dispatch(logoutUserAction({token: userData.token}))
    navigate('/login')
  }
  return (
    <div>
      <div>{t('home')}</div>{' '}
      <Form onSubmit={handleSubmit}>
        <Button type="submit">{t('logout')}</Button>
      </Form>
    </div>
  )
}
export default Home
