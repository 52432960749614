import React from 'react'
import {Form, Container, Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import Close from '../../atoms/illustrations/Close'
import '../addAgentForm/addAgentForm.css'
import '../loginForm/loginForm.css'
import {callSettingsValidationSchema} from '../../../utils/validations/validation'
import Spacer from '../../atoms/spacer/Spacer'
import LoadingButton from '../../atoms/button/Button'
import {AgentCallSettingsFormProps} from './agentCallSettingForm.props'
import './agentCallSettingForm.css'

const AgentCallSettingForm: React.FC<AgentCallSettingsFormProps> = ({handleChangeCallSettings}) => {
  const {t} = useTranslation()
  const initialValues = {
    contactPerson: '',
    address: '',
    country: '',
    city: '',
    state: '',
    postalCode: '',
    email: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
  }
  const FormGroupWithClear = ({
    label,
    name,
    value,
    onChange,
    errors,
    touched,
    setFieldValue,
    placeholder,
  }: any) => (
    <Form.Group className="w-100">
      <div className="password-field-input">
        <p className="form-labels pb-1 m-0">{t(label)}</p>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={!!errors[name] && touched[name]}
          className="custom-input"
          placeholder={placeholder}
        />
        {value && value.length > 0 && (
          <Button variant="link" onClick={() => setFieldValue(name, '')} className="cancel-button">
            <Close />
          </Button>
        )}
        <Form.Control.Feedback type="invalid">
          {`${t(label)} ${t(errors[name] || '')}`}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  )
  return (
    <Container fluid className="pt-2 pb-3">
      <div className="add-agent-form-view add-agent-form">
        <Formik
          initialValues={initialValues}
          validationSchema={callSettingsValidationSchema}
          onSubmit={(values, {setSubmitting}) => {
            handleChangeCallSettings(values)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            isSubmitting,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit} className="agent-call-settings-form">
              <div className="d-flex flex-column justify-content-between align-items-center h-100 ">
                <div className="w-100">
                  <FormGroupWithClear
                    label="contactPerson"
                    name="contactPerson"
                    value={values.contactPerson}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    placeholder={t('contactPerson')}
                  />
                  <Spacer size={16} />
                  <FormGroupWithClear
                    label="address"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    placeholder={t('address')}
                  />

                  <Spacer size={16} />
                  <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                    <FormGroupWithClear
                      label="country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      placeholder={t('country')}
                    />
                    <FormGroupWithClear
                      label="city"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      placeholder={t('city')}
                    />
                    <FormGroupWithClear
                      label="state"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      placeholder={t('state')}
                    />
                    <FormGroupWithClear
                      label="postalCode"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      placeholder={t('postalCode')}
                    />
                  </div>
                  <Spacer size={16} />
                  <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                    <FormGroupWithClear
                      label="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      placeholder={t('email')}
                    />

                    <div className="w-100 password-field-input">
                      <p className="form-labels pb-1 m-0">{t('phoneNumber')}</p>
                      <Form.Control
                        type="tel"
                        name="phoneNumber"
                        placeholder={`${t('phoneNumberEg')}`}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '')
                        }}
                        isInvalid={!!(touched.phoneNumber && errors.phoneNumber)}
                        className="custom-input"
                      />
                      {touched.phoneNumber && errors?.phoneNumber && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                          {`${t('phoneNumber')} ${t(errors?.phoneNumber || '')}`}
                        </Form.Control.Feedback>
                      )}
                      {values && values.phoneNumber.length > 0 && (
                        <Button
                          variant="link"
                          onClick={() => setFieldValue('phoneNumber', '')}
                          className="cancel-button"
                        >
                          <Close />
                        </Button>
                      )}
                    </div>
                  </div>
                  <Spacer size={16} />
                  <div className="w-100 password-field-input">
                    <p className="form-labels pb-1 m-0">{t('alternatePhoneNumber')}</p>
                    <Form.Control
                      type="tel"
                      name="alternatePhoneNumber"
                      placeholder={`${t('alternatePhoneNumber')}`}
                      value={values.alternatePhoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '')
                      }}
                      isInvalid={!!(touched.alternatePhoneNumber && errors.alternatePhoneNumber)}
                      className="custom-input"
                    />
                    {touched.alternatePhoneNumber && errors?.alternatePhoneNumber && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {`${t('alternatePhoneNumber')} ${t(errors?.alternatePhoneNumber || '')}`}
                      </Form.Control.Feedback>
                    )}
                    {values && values.alternatePhoneNumber.length > 0 && (
                      <Button
                        variant="link"
                        onClick={() => setFieldValue('alternatePhoneNumber', '')}
                        className="cancel-button"
                      >
                        <Close />
                      </Button>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center w-100 save-btn-view">
                  <LoadingButton
                    buttonText={t('save')}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isCurvedButton
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

export default AgentCallSettingForm
