import men from '../../assets/images/men.png'
import women from '../../assets/images/women.png'
import help1 from '../../assets/images/help1.png'
import help2 from '../../assets/images/help2.png'
import help3 from '../../assets/images/help3.png'
import help4 from '../../assets/images/help4.png'
import help5 from '../../assets/images/help5.png'
import help6 from '../../assets/images/help6.png'

export const authInfoData = [
  {
    id: 1,
    label: 'Introducing ',
    title: 'VoicerAI',
    description:
      'An AI receptionist that handles all your bookings and fill up your staff schedule.',
    image: men,
  },
  {
    id: 2,
    label: 'Introducing ',
    title: 'VoicerAI',
    description:
      'An AI receptionist answers all general information about your business, and upsells your promotions or services on each call.',
    image: women,
  },
  {
    id: 3,
    label: 'Introducing ',
    title: 'VoicerAI',
    description:
      'An AI receptionist available 24/7, and upsells your promotions or services on each call',
    image: women,
  },
]

export const jobCallsList = [
  {
    title: 'Total Calls Received',
    numberOfCalls: 434,
  },
  {
    title: 'Total Minutes of Calls',
    numberOfCalls: 234,
  },
  {
    title: 'Total Remaining Calls',
    numberOfCalls: 204,
  },
]
export const data = [
  {name: 'Jan', uv: 10, amt: 15},
  {name: 'Feb', uv: 20, amt: 20},
  {name: 'Mar', uv: 30, amt: 40},
  {name: 'Apr', uv: 25, amt: 60},
  {name: 'May', uv: 50, amt: 80},
  {name: 'Jun', uv: 80, amt: 100},
]

export const agentData = [
  {
    title: 'Jane Cooper',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
  {
    title: 'Jane Cooper',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
  {
    title: 'Jane Cooper',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
  {
    title: 'Jane Cooper',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma',
  },
]
export const yearOptions = [2024, 2023, 2022, 2021]
export const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const daysConstantData = {
  '14': [
    {
      id: 2,
      appointment: '2024-11-14 17:00:00',
      note: 'not available',
      cancel_at: '2023-11-20 17:00:00',
      cancel_note: 'not available',
      created_at: '2024-05-16 11:54:52',
      updated_at: '2024-05-16 11:54:52',
      deleted_at: null,
      organization_id: 1,
      employee_id: 1,
      day: 14,
    },
    {
      id: 4,
      appointment: '2024-11-14 17:00:00',
      note: 'available',
      cancel_at: null,
      cancel_note: null,
      created_at: '2024-05-16 11:56:02',
      updated_at: '2024-05-22 08:58:54',
      deleted_at: null,
      organization_id: 1,
      employee_id: 1,
      day: 14,
    },
  ],
  '20': [
    {
      id: 1,
      appointment: '2024-11-20 17:00:00',
      note: 'not available',
      cancel_at: '2024-05-22 08:54:46',
      cancel_note: 'csdc',
      created_at: '2024-05-16 11:22:35',
      updated_at: '2024-05-22 08:54:46',
      deleted_at: null,
      organization_id: 1,
      employee_id: 1,
      day: 20,
    },
    {
      id: 3,
      appointment: '2024-11-20 17:00:00',
      note: 'not available',
      cancel_at: null,
      cancel_note: 'not available',
      created_at: '2024-05-16 11:55:55',
      updated_at: '2024-05-22 08:55:51',
      deleted_at: null,
      organization_id: 1,
      employee_id: 1,
      day: 20,
    },
  ],
}

export const appointmentSettingsOptions = {
  slotAvailability: ['Available', 'Unavailable', 'Pending'],
  slotsSeparator: ['Available', 'Unavailable', 'Pending'],
  slotsError: ['Error1', 'Error2', 'Error3'],
  noSlotsAvailable: ['None', 'Few', 'Many'],
  errorBookingAppointment: ['Error1', 'Error2', 'Error3'],
  appointmentBooked: ['Success', 'Failure', 'Pending'],
  slotIndex: ['1', '2', '3'],
}

export const notificationData = [
  {
    title: 'New Call Reviced',
    time: 'June 12',
  },
  {
    title: 'New Call Reviced',
    time: 'June 12',
  },
  {
    title: 'New Call Reviced',
    time: 'June 12',
  },
  {
    title: 'New Call Reviced',
    time: 'June 12',
  },
  {
    title: 'New Call Reviced',
    time: 'June 12',
  },
  {
    title: 'New Call Reviced',
    time: 'June 12',
  },
]

export const planList = [
  {
    planName: 'Basic',
    planType: 'Receptionist',
    price: '49.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: true,
  },
  {
    planName: 'Plus',
    planType: 'Receptionist +',
    price: '99.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: false,
  },
  {
    planName: 'Plus',
    planType: 'Receptionist +',
    price: '99.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: false,
  },
  {
    planName: 'Basic',
    planType: 'Receptionist',
    price: '49.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: true,
  },
  {
    planName: 'Basic',
    planType: 'Receptionist',
    price: '49.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: true,
  },
  {
    planName: 'Basic',
    planType: 'Receptionist',
    price: '49.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: true,
  },
  {
    planName: 'Basic',
    planType: 'Receptionist',
    price: '49.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: true,
  },
  {
    planName: 'Basic',
    planType: 'Receptionist',
    price: '49.99',
    features: [
      'Answers calls',
      'Book appointments',
      'Calendars special notes',
      '10 FAQs',
      'Limited hours of call received monthly',
    ],
    isCurrent: true,
  },
]
export const activityTableData = [
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
  {
    name: 'Cameron Williamson',
    activity: 'Changed Password',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: 'Jun 6, 2021 02:37:22 PM EDT',
  },
]

export const callHistoryTableData = [
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
  {
    name: 'Albert Flores',
    image:
      'https://as2.ftcdn.net/v2/jpg/04/31/64/75/1000_F_431647519_usrbQ8Z983hTYe8zgA7t1XVc5fEtqcpa.jpg',
    time: '06:32 pm',
    duration: '44m',
    callCost: '$.045',
  },
]

export const halpArray = [
  {image: help1, title: 'The unseen of spending three years at Pixelgrade'},
  {image: help2, title: 'How to choose the right customer for your photo business?'},
  {image: help3, title: 'Where to grow your business as a photographer: site or social media?'},
  {image: help4, title: 'How to design your site footer like we did'},
  {image: help5, title: 'How to optimize images in WordPress for faster loading (complete guide)'},
  {image: help6, title: 'Travelling as a way of self-discovery and progress'},
]
