import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Modal, Spinner} from 'react-bootstrap'
import './agent.css'
import {useNavigate} from 'react-router-dom'
import AgentCard from '../../molecules/authInfo/agentCard/AgentCard'
import AddButton from '../../atoms/illustrations/AddButton'
import Spacer from '../../atoms/spacer/Spacer'
import {useAppDispatch, useSelector} from '../../../store'
import {agentListAction, deleteAgentAction} from '../../../store/action/agent'
import showNotification from '../../../function/notification'
import {RootState} from '../../../store/slices'
import noAgentImage from '../../../assets/images/noAgent.png'
import {AgentDataType} from './agent.props'
import Loader from '../../atoms/Loader'

const AgentScreen = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const agentActions = [{title: t('generalPrompt')}, {title: t('importAgent')}]
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState<string>()
  const [loading, setLoading] = useState(false)
  const agentData = useSelector((state: RootState) => state.agent?.agentList)

  const getAgentList = async () => {
    try {
      setLoading(true)
      await dispatch(agentListAction({})).unwrap()
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }
  useEffect(() => {
    getAgentList()
  }, [])
  const handleDeleteAgent = async (id: any) => {
    setLoading(true)
    try {
      const response = await dispatch(deleteAgentAction({agent_slug: id})).unwrap()
      await getAgentList()
      showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
    setDeleteModal(false)
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="agent-view">
          <div className="agent-actions-view d-flex justify-content-between align-items-center flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row">
            <div className="w-80">
              {agentData.length === 0 && (
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  onClick={() => navigate('/add-agent')}
                >
                  <div className=" add-agent-view d-flex flex-row justify-content-center align-items-center">
                    <p className="number-of-agent-text p-0 m-0">{agentData.length}</p>
                    <p className="total-agent-text  m-0">{t('totalAgents')}</p>
                    <AddButton />
                  </div>
                </button>
              )}
            </div>

            {/* <div className="d-flex flex-row justify-content-end align-items-center gap-2">
              {agentActions.map((item, index) => (
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  key={`${item.title}-${index + 2}`}
                >
                  <div className="agent-action-button">
                    <p className="agent-action-text p-0 m-0">{item.title}</p>
                  </div>
                </button>
              ))}
            </div> */}
          </div>
          <Spacer size={8} />
          {agentData.length > 0 ? (
            <div className="agent-listing">
              {agentData.map((item: AgentDataType, index) => (
                <AgentCard
                  description={item.voicemailMessage}
                  title={item.name}
                  key={`${item.name}-${index + 2}`}
                  setModal={() => navigate(`/add-agent/${item?.slug}`)}
                  handleDeleteAgent={() => {
                    setDeleteId(item.slug)
                    setDeleteModal(true)
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="no-agent-view d-flex flex-column justify-content-center align-items-center">
              <img src={noAgentImage} alt="no agent" />
              <Spacer size={24} />
              <button
                className="add-agent-button border-0 rounded-5"
                type="button"
                aria-label={t('addFirstAgent')}
                onClick={() => navigate('/add-agent')}
              >
                <p className="add-agent-btn-text m-0">{t('addFirstAgent')}</p>
              </button>
            </div>
          )}
          <Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
            <div className="d-flex flex-column delete-agent-view">
              <p className="delete-agent-title">{t('deleteAgent')}</p>
              <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                <p className="delete-agent-description m-0">
                  {t('deleteAgentDesc')}
                  <b> &quot;{agentData?.[0]?.name}&quot; </b>
                </p>
              </div>
              <Spacer size={16} />
              <button
                type="button"
                className="remove-btn border-0 rounded-3 p-3"
                onClick={() => handleDeleteAgent(deleteId)}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  <p className="p-0 m-0 remove-btn-text">{t('deleteAgent')}</p>
                )}
              </button>
              <Spacer size={8} />
              <button
                type="submit"
                className="next-btn  rounded-3 p-3"
                onClick={() => setDeleteModal(false)}
              >
                <p className="p-0 m-0 next-btn-text"> {t('cancel')}</p>
              </button>
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}

export default AgentScreen
