import React from 'react'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import {Form} from 'react-bootstrap'
import Spacer from '../../atoms/spacer/Spacer'
import '../loginForm/loginForm.css'
import {ForgotPasswordValidationSchema} from '../../../utils/validations/validation'
import {ForgotPasswordFormProps} from './fotgotPasswordForm.props'
import LoadingButton from '../../atoms/button/Button'

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({handleSendLink, loading}) => {
  const {t} = useTranslation()
  const initialValues = {
    email: '',
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordValidationSchema}
        onSubmit={(values, {setSubmitting}) => {
          handleSendLink(values)
          setSubmitting(false)
        }}
      >
        {({handleSubmit, handleChange, values, errors, isValid}) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <p className="form-labels pb-1 m-0">{t('email')}</p>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  {`${t('email')} ${t(errors?.email || '')}`}
                </Form.Control.Feedback>
              </Form.Group>
              <LoadingButton buttonText={t('sendLink')} isSubmitting={loading} isValid={isValid} />
              <Spacer size={24} />
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

export default ForgotPasswordForm
