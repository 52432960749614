import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {LoginResponse, User} from '../../type/api/auth'
import {loginUserAction, logoutUserAction} from '../action/auth'
import {formatAuthUser} from '../../utils/redux/auth'
import {setAuthorization} from '../../service/api'
import {DeactivateAccountAction} from '../action/profile'

export interface AuthState {
  user: User
}
const initialState: AuthState = {
  user: {
    email: '',
    emailVerificationToken: '',
    id: '',
    name: '',
    organizationId: '',
    roleId: '',
    slug: '',
    token: '',
    profileImage: '',
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUserAction.fulfilled, (state, action: PayloadAction<LoginResponse>) => {
      setAuthorization(action.payload.data.token)
      state.user = formatAuthUser(action.payload.data)
    })
    builder.addCase(logoutUserAction.fulfilled, (state) => {
      state.user = initialState.user
    })
    builder.addCase(DeactivateAccountAction.fulfilled, (state) => {
      state.user = initialState.user
    })
  },
})
