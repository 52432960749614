import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppDispatch} from '../../../store'
import AuthTemplate from '../../template/authTemplate/AuthTemplate'
import ForgotPasswordForm from '../../organism/forgotPasswordForm/ForgotPasswordForm'
import {authInfoData} from '../../../utils/constants/constant'
import background from '../../../assets/images/background.png'
import {forgotPasswordAction} from '../../../store/action/auth'
import {ForgotPasswordFormFields} from '../../organism/forgotPasswordForm/fotgotPasswordForm.props'
import showNotification from '../../../function/notification'

const ForgotPasswordScreen = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const handleForgotPassword = async (values: ForgotPasswordFormFields) => {
    try {
      setLoading(true)
      await dispatch(forgotPasswordAction({email: values.email})).unwrap()
      showNotification({
        title: `${t('success')}`,
        type: 'success',
        message: `${t('resetEmailSent')}`,
      })
    } catch (error) {
      showNotification({
        title: t('invalid'),
        type: 'danger',
        message: `${error}`,
      })
    }
    setLoading(false)
  }
  return (
    <AuthTemplate
      FormComponent={
        <ForgotPasswordForm
          handleSendLink={(values: ForgotPasswordFormFields) => handleForgotPassword(values)}
          loading={loading}
        />
      }
      authInfo={authInfoData}
      title={t('forgotPasswordTitle')}
      description={t('forgotPasswordDesc')}
      image={background}
    />
  )
}

export default ForgotPasswordScreen
