import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {verifyEmail} from '../../../api/auth'

const EmailVerificationScreen = () => {
  const navigate = useNavigate()

  const [route] = useSearchParams()

  useEffect(() => {
    setTimeout(() => {
      navigate('/login', {replace: true})
    }, 3000)
  }, [])

  const getEmailVerified = async (email: string, token: string) => {
    try {
      const response = await verifyEmail({email, token})
      if (response?.data?.status) {
        navigate(`/verify-email-success?message=${response?.data?.message}`)
      } else {
        navigate(`/verify-email-failure?message=${response?.data?.message}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const email = route.get('email')
    const token = route.get('token')
    if (email && token) {
      getEmailVerified(email, token)
    } else {
      navigate('/login')
    }
  }, [route])

  return <>Loading...</>
}

export default EmailVerificationScreen
