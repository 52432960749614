import React from 'react'

const Pen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" fill="none" viewBox="0 0 22 21">
      <g clipPath="url(#clip0_818_6393)">
        <mask
          id="mask0_818_6393"
          style={{maskType: 'alpha'}}
          width="22"
          height="21"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M.418.088h5.249l10.36 10.36 1.218-1.218L8.103.088h13.21v20.895H.418V.088z"
          />
        </mask>
        <g mask="url(#mask0_818_6393)">
          <path
            stroke="#A7A7A7"
            strokeWidth="2.985"
            d="M5.676 16.878H4.522v-1.154L14.751 5.496l.686-.686 1.154 1.154-.686.686.985.985-.985-.985L5.676 16.878z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_818_6393">
          <path fill="#fff" d="M0 0H20.895V20.895H0z" transform="translate(.418 .088)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Pen
