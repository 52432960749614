import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import AuthTemplate from '../../template/authTemplate/AuthTemplate'
import {authInfoData} from '../../../utils/constants/constant'
import SignUpForm from '../../organism/signUpForm/SignUpForm'
import background from '../../../assets/images/background.png'
import {register} from '../../../api/auth'
import {SignUpFormFields} from '../../organism/signUpForm/signUpForm.props'
import {AcceptanceStatus} from '../../../type/api/auth'
import showNotification from '../../../function/notification'
import {useSelector} from '../../../store'
import {RootState} from '../../../store/slices'

const SignUpScreen = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const token = useSelector((state: RootState) => state.auth.user?.token)
  const navigate = useNavigate()
  const signUpUser = async (values: SignUpFormFields) => {
    setLoading(true)
    try {
      const registrationResponse = await register({
        age_terms_accepted: AcceptanceStatus.Yes,
        email: values.email,
        country_code_alpha: values.countryCodeAlpha?.countryCodeAlpha,
        phone: values?.phoneNumber,
        name: values.fullName,
        password: values.password,
        password_confirmation: values.confirmPassword,
        zipcode: values.zipCode,
      })
      if (registrationResponse.data.status) {
        showNotification({
          title: `${t('success')}`,
          type: 'success',
          message: `${registrationResponse?.data?.message}`,
        })
        navigate('/')
      } else {
        showNotification({
          title: t('invalid'),
          type: 'danger',
          message: `${registrationResponse.data.message}`,
        })
      }
    } catch (error) {
      showNotification({title: `${error}`, type: 'warning', message: `${error}`})
    }
    setLoading(false)
  }

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [])
  return (
    <AuthTemplate
      title={t('createAccount')}
      description={t('setupAccount')}
      authInfo={authInfoData}
      FormComponent={
        <SignUpForm
          handleSignUp={(values: SignUpFormFields) => signUpUser(values)}
          loading={loading}
        />
      }
      image={background}
    />
  )
}

export default SignUpScreen
