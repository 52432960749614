import {EmployeeResponseType} from '../../type/api/employee'

export const formatEmployeeList = (data: EmployeeResponseType[]) => {
  console.log(data, 'data')
  return data.map((item) => ({
    id: item?.id,
    name: item?.name,
    slug: item?.slug,
    phone: item?.phone,
    address: item?.address,
    city: item?.city,
    zipcode: item?.zipcode,
    about: item?.about,
    timeSlot: item?.time_slot_minute,
    createdAt: item?.created_at,
    updatedAt: item?.updated_at,
    userId: item?.user_id,
    countryId: item?.country_id,
    organizationId: item?.organization_id,
    profileImage: item?.profile_image?.original_url,
    leaves: item?.leaves.map((leave) => ({
      id: leave?.id,
      startDate: leave?.from_date_time,
      endDate: leave?.to_date_time,
    })),
    email: item?.user?.email,
  }))
}
