import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import Spacer from '../../atoms/spacer/Spacer'
import logo from '../../../assets/images/logo.jpg'
import AuthInfo from '../../molecules/authInfo/AuthInfo'
import {AuthTemplateProps} from './authTemplate.props'
import './authTemplate.css'
import ArrowLeft from '../../atoms/illustrations/ArrowLeft'

const AuthTemplate: React.FC<AuthTemplateProps> = ({
  title,
  description,
  authInfo,
  FormComponent,
  noBack,
  isVerification,
}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const handleRedirect = () => {
    window.location.href = 'https://nexxkai.com/'
  }
  return (
    <Container fluid>
      <Row className="auth-row">
        <Col
          md={6}
          className="left-column col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-start align-items-center flex-column p-0 m-0"
        >
          <div className="logo-view">
            <button className="border-0 bg-transparent" type="button" onClick={handleRedirect}>
              <img src={logo} height={20} width={100} alt="logo" />
            </button>
          </div>
          <div className="left-column-view">
            {!noBack && (
              <>
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-none p-0"
                  aria-label={t('backLabel')}
                  type="button"
                >
                  <ArrowLeft />
                </button>
                <Spacer size={24} />
              </>
            )}
            <p
              className={`p-0 m-0 ${
                isVerification ? 'heading-verification-text text-center' : 'heading-text '
              }`}
            >
              {title}
            </p>
            <p className={`description-text p-0 m-0 ${isVerification ? 'text-center' : ''}`}>
              {description}
            </p>
            <Spacer size={32} />
            {FormComponent}
          </div>
        </Col>
        <Col md={6} className="right-column col-md-12 col-lg-6 col-xl-6 col-xxl-6  ">
          <div className="right-info-view h-100">
            <AuthInfo authInfo={authInfo} />
          </div>
          <p className="footer-text text-center">{t('copyRightText')}</p>
        </Col>
      </Row>
    </Container>
  )
}

export default AuthTemplate
