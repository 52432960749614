import React, {useState} from 'react'
import {Form, Button, Container} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import {useAppDispatch} from '../../../store'
import './loginForm.css'
import Spacer from '../../atoms/spacer/Spacer'
import {loginValidationSchema} from '../../../utils/validations/validation'
import EyeOpen from '../../atoms/illustrations/EyeOpen'
import {LoginFormProps} from './loginForm.props'
import LoadingButton from '../../atoms/button/Button'
import EyeClose from '../../atoms/illustrations/EyeClose'
import {resendVerificationAction} from '../../../store/action/auth'
import showNotification from '../../../function/notification'

const LoginForm: React.FC<LoginFormProps> = ({
  handleLogin,
  loading,
  errorText,
  setErrors,
  setEmailError,
  emailError,
}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [emailValue, setEmailValue] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const [emailLoading, setEmailLoading] = useState(false)
  const initialValues = {
    email: '',
    password: '',
  }
  const handleResendVerificationEmail = async () => {
    try {
      setEmailLoading(true)
      const response = await dispatch(resendVerificationAction({email: emailValue})).unwrap()
      showNotification({
        title: t('success'),
        type: 'success',
        message: `${response?.message}`,
      })
      setEmailError(undefined)
    } catch (error) {
      showNotification({
        title: t('error'),
        type: 'danger',
        message: `${error}`,
      })
    }
    setEmailLoading(false)
  }
  return (
    <Container className="login-form-container">
      <Formik
        initialValues={initialValues}
        validationSchema={loginValidationSchema}
        onSubmit={(values, {setSubmitting}) => {
          handleLogin(values)
          setSubmitting(false)
        }}
      >
        {({handleSubmit, handleChange, values, errors, touched, isValid}) => {
          const isFormValid = Boolean(values.email) && Boolean(values.password) && isValid

          return (
            <Form onSubmit={handleSubmit} className="w-100">
              <Form.Group controlId="formBasicEmail">
                <p className="form-labels pb-1 m-0">{t('email')}</p>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder={`${t('emailEg')}`}
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e)
                    setErrors(undefined)
                    setEmailError(undefined)
                    setEmailValue(e.target.value)
                  }}
                  isInvalid={!!errors.email && touched.email}
                  className={`custom-input ${errorText ? '' : ''}`}
                />
                <Form.Control.Feedback type="invalid">
                  {`${t('email')} ${t(errors?.email || '')}`}
                </Form.Control.Feedback>
              </Form.Group>
              <Spacer size={8} />
              <Form.Group controlId="formBasicPassword">
                <p className="form-labels pb-1 m-0">{t('password')}</p>
                <div className="password-field-input">
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder={`${t('password')}`}
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e)
                      setEmailError(undefined)
                      setErrors(undefined)
                    }}
                    isInvalid={!!errors.password && touched.password}
                    className={`custom-input ${errorText ? '' : ''}`}
                  />
                  <Button
                    variant="link"
                    onClick={togglePasswordVisibility}
                    className={`eye-button ${
                      errors.password && touched.password ? 'error-occurred' : ''
                    }`}
                    aria-label={showPassword ? t('hidePassword') : t('showPassword')}
                  >
                    {showPassword ? <EyeOpen /> : <EyeClose />}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {`${t('password')} ${t(errors?.password || '')}`}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Spacer size={12} />

              <Spacer size={8} />
              {/* {errorText && errorText.trim() !== '' && (
                <p className="error-text-show pb-4 m-0">{errorText}</p>
              )} */}
              <div className="d-flex flex-row justify-content-between align-items-center">
                <a href="/forgot-password" className="forgot-text">
                  {t('forgotPassword')}
                </a>
                {emailError && (
                  <button
                    className="bg-transparent border-0"
                    type="button"
                    aria-label="resendBtn"
                    onClick={handleResendVerificationEmail}
                  >
                    <p className="forgot-text p-0 m-0">{t('resendEmail')}</p>
                  </button>
                )}
              </div>

              <LoadingButton
                buttonText={t('signIn')}
                isSubmitting={loading || emailLoading}
                isValid={isFormValid}
              />
              <Spacer size={24} />
              <div className="register-container">
                <p className="register-text p-0 m-0">
                  {t('notHaveAccount')}
                  <a href="/signup" className="forgot-text">
                    {t('register')}
                  </a>
                </p>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default LoginForm
