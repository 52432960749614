import React from 'react'

const User = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#49454F"
        fillRule="evenodd"
        d="M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm2 4c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm4 9c-.2-.71-3.3-2-6-2s-5.8 1.29-6 2.01V18h12v-1zM4 17c0-2.66 5.33-4 8-4s8 1.34 8 4v3H4v-3z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default User
