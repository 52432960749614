import React, {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import './myAppointments.css'
import Spacer from '../../atoms/spacer/Spacer'
import {daysOfWeek} from '../../../utils/constants/constant'
import {useAppDispatch, useSelector} from '../../../store'
import {RootState} from '../../../store/slices'
import {appointmentListAction} from '../../../store/action/appointment'
import showNotification from '../../../function/notification'
import Previous from '../../atoms/illustrations/Previous'
import Next from '../../atoms/illustrations/Next'

const MyAppointmentScreen = () => {
  const {t} = useTranslation()
  const [currentDate, setCurrentDate] = useState(moment())
  const currentMonth = moment(currentDate).format('MMMM YYYY')
  const startOfMonth = moment(currentDate).startOf('month').day()
  const daysInMonth = moment(currentDate).daysInMonth()
  const dispatch = useAppDispatch()
  const daysData = useSelector((state: RootState) => state.appointment?.appointmentList)
  const handlePrevious = () => {
    setCurrentDate(currentDate.clone().subtract(1, 'months'))
  }
  const handleFetchAppointments = async () => {
    try {
      await dispatch(
        appointmentListAction({month: currentDate.month() + 1, year: currentDate.year()}),
      ).unwrap()
    } catch (error) {
      showNotification({
        title: t('error'),
        type: 'danger',
        message: `${error}`,
      })
    }
  }
  const handleNext = () => {
    setCurrentDate(currentDate.clone().add(1, 'months'))
  }

  useEffect(() => {
    handleFetchAppointments()
  }, [currentDate])
  const renderDays = () => {
    const weeks: any[] = []

    let days = Array.from({length: startOfMonth}, (_, i) => (
      <td
        key={`empty-start-${i}`}
        aria-label="table-data"
        className="empty-table-body justify-content-start ps-3 flex-column justify-content-between border-0"
      />
    ))

    Array.from({length: daysInMonth}, (_, day) => day + 1).map((day) => {
      days.push(
        <td
          key={day}
          className="table-body-view justify-content-start ps-3 flex-column justify-content-between"
        >
          <p className="table-header-text m-0 border-0">{day}</p>
          <div className="table-data-events">
            {daysData[day - 1]?.placeholders.map((placeholder: any, index) => (
              <>
                {placeholder.note && (
                  <p key={`${day}-${index + 2}`} className="table-events-text mb-1 me-1">
                    {placeholder.note}
                  </p>
                )}
              </>
            ))}
          </div>
        </td>,
      )

      if (days.length % 7 === 0 || day === daysInMonth) {
        while (days.length < 7) {
          days.push(
            <td
              key={`empty-${days.length}`}
              aria-label="table-data"
              className="empty-table-body justify-content-start ps-3 flex-column justify-content-between border-0"
            />,
          )
        }

        weeks.push(
          <>
            <Spacer size={4} />
            <tr key={`week-${weeks.length}`} className="table-row justify-content-start">
              {days}
            </tr>
          </>,
        )
        days = []
      }
      return null
    })

    return weeks
  }

  return (
    <div className="p-3">
      <div className="d-flex flex-row justify-content-between align-items-center gap-5">
        <div>
          <p className="month-text m-0">{currentMonth}</p>
        </div>
        <div className="d-flex flex-row justify-content center gap-5 me-4">
          <button
            className="bg-transparent border-0"
            type="button"
            aria-label="Previous"
            onClick={handlePrevious}
          >
            <Previous />
          </button>
          <button
            className="bg-transparent border-0"
            type="button"
            aria-label="Next"
            onClick={handleNext}
          >
            <Next />
          </button>
        </div>
      </div>
      <Spacer size={12} />
      <Table className="table">
        <thead>
          <tr className="table-row">
            {daysOfWeek.map((day, index) => (
              <th key={`${day} - ${index + 2}`} className="table-header-view">
                <p className="table-header-text m-0 border-0">{day}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderDays()}</tbody>
      </Table>
    </div>
  )
}

export default MyAppointmentScreen
