import React from 'react'

const Close = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#000"
        d="M15.833 5.341l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.341 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.659z"
      />
    </svg>
  )
}

export default Close
