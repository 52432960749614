const english = {
  translation: {
    404: 404,
    home: 'Home',
    createAccount: 'Create Account',
    setupAccount: 'Let’s  setup your account',
    notHaveAccount: " Don't have an account? ",
    register: 'Register',
    signIn: 'Sign In',
    forgotPassword: 'Forgot Password?',
    copyRightText: '@2024 NeXxKai All Right Reserved.',
    signInWithUs: ' By creating an account or signing you agree to our',
    termsCondition: 'Terms and Conditions',
    email: 'Email',
    emailEg: 'Enter email',
    fullName: 'Full Name',
    fullNameEg: 'Enter your full name',
    phoneNumber: 'Phone Number',
    phoneNumberEg: 'Enter your phone number',
    zipCode: 'Zip Code',
    zipCodeEg: 'Enter your Zip code',
    postalCodeEg: 'Enter your Postal code',
    postalCode: 'Postal Code',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    isInValid: 'is Invalid',
    isRequired: 'is required',
    mustBeTenDigits: 'must be exactly 10 digits',
    mustBeFiveCharacters: 'must be 5 characters',
    mustBeEightCharacters: 'must be at least 8 characters',
    passwordMatch: 'must match',
    passwordError: 'must contain at least one uppercase letter, one number',
    mustContainUppercase: 'must contain at least one uppercase letter',
    mustContainLowercase: 'must contain at lower one uppercase letter',
    mustContainNumber: 'Must Contain a Number',
    welcomeText: 'Welcome to NeXxKai',
    loginToAccount: 'Login to your account',
    loginSuccessFull: 'Login SuccessFully!',
    countryCode: 'Country',
    logout: 'Logout',
    forgotPasswordTitle: 'Forgot Password',
    forgotPasswordDesc: 'We will send a new password to your account from email',
    sendLink: 'Send Link',
    resetPassword: 'Reset Password',
    resetPasswordDesc: 'Send your email account to reset password and make new password',
    resetEmailSent: 'we have sent you reset password link on your email please check email ',
    success: 'Success',
    atLeastFiveCharacters: 'At least five Characters',
    dashboard: 'Dashboard',
    agent: 'Agent',
    myAppointments: 'My Appointments',
    callHistory: 'Call History',
    callSettings: 'Call Settings',
    appointmentsSetting: 'Appointment Settings',
    accountActivity: 'Account Activity',
    pricing: 'Pricing',
    help: 'Help',
    deactivateAccount: 'Deactivate Account',
    searchAnything: 'Search anything…',
    settings: 'Settings',
    others: 'Others',
    error: 'Error',
    invalid: 'Invalid',
    totalCallDetails: 'Total Appointment Details',
    totalCallReceives: 'Total Call Receives',
    generalPrompt: 'Generate Prompt',
    importAgent: 'Import Agent',
    totalAgents: 'Total Agents',
    agentName: 'Agent Name',
    organizationName: 'Organization Name',
    greetingMessage: 'Greeting Message',
    personality: 'Personality',
    rules: 'Rules',
    agenda: 'Agenda',
    voicemailMessage: 'Voicemail Message',
    companyInfo: 'About',
    questionGuideline: 'Guideline for qualifying question(s)',
    questionGuidelineKey: 'Guideline for qualifying question(s) Key',
    qualifyGuideline: 'Post qualifying question(s)',
    qualifyGuidelineKey: 'Post qualifying question(s) Key',
    miscellaneous: 'Miscellaneous',
    addAgent: 'Add Agent',
    qualifyError: 'Guidelines Key and Guideline  is required',
    questionError: 'Faq Key and  Faq  is required',
    miscellaneousError: 'Miscellaneous and Miscellaneous Key is Required',
    rulesError: 'Rule and Rule key is Required',
    voiceMailMsg: 'Voice Mail Message',
    remove: 'Remove',
    next: 'Next',
    save: 'Save',
    contactPerson: 'Contact Person',
    address: 'Address',
    country: 'Country',
    city: 'City',
    state: 'State / Province',
    alternatePhoneNumber: 'Alternate Phone Number',
    slotAvailability: 'Slot Availability',
    slotsSeparator: 'Slots Separator',
    slotsError: 'Slots error',
    slotErrorEg: 'Please enter error message',
    noSlotsAvailable: 'No Slots available',
    noSlotsAvailableEg: 'Please enter unavailibity message',
    enterSuccessMsg: 'Please enter success message',
    errorBookingAppointment: 'Error Booking Appointment',
    appointmentBooked: 'Appointment Booked',
    slotIndex: 'Slot Index',
    appointmentSettings: 'Appointment Settings',
    appointmentSettingsDesc: 'Set the Company Display Appropriately and Attractive',
    select: 'Select',
    human: 'Human',
    bot: 'Bot',
    backLabel: 'Go Back',
    addGuideLine: 'Add guideline',
    removeGuideLine: 'Remove guideline',
    showPassword: 'Show Password',
    hidePassword: 'Hide Password',
    organization: 'Organization',
    timeSlot: 'Time Slot Minute',
    about: 'About',
    profileImage: 'Profile Image',
    dragToUpload: 'Drag files here to upload',
    totalSales: 'Total Sales',
    totalRevenue: 'Total Revenue',
    miscellaneousKey: 'Miscellaneous key',
    rulesKey: 'Rules key',
    deleteAgent: 'Delete Agent',
    deleteAgentDesc: 'Are you sure you want to delete Agent',
    cancel: 'Cancel',
    addFirstAgent: 'Add Your First AI Agent',
    firstName: 'First Name',
    lastName: 'Last Name',
    postal: 'Postal',
    editProfile: 'Edit Profile',
    photoProfile: 'Photo Profile',
    areYouSure: 'Are you sure?',
    deactivateDesc:
      "We're sorry to see you go. Please remember, account deactivation doesn’t mean deleting your account. You’re account will be deleted after 30 days of inactivity.",
    goBack: 'Go Back',
    profileUpdated: 'Profile Updated Successfully',
    addEmployeeLeave: 'Add Employee Leave',
    ddmmyy: 'dd/mm/yyyy',
    dateError: 'please select start and end date',
    employee: 'Employee',
    guideline: 'Guidelines',
    faq: 'Faq',
    notification: 'Notification',
    currentPlan: 'Current Plan',
    upgradePlan: 'Upgrade Plan',
    perMonth: 'per Month',
    name: 'Name',
    activity: 'Activity',
    dateAndTime: 'Date & Time',
    action: 'Action',
    showing: 'Showing',
    entries: 'entries',
    of: 'of',
    previous: 'Previous',
    time: 'Time',
    duration: 'Duration',
    callCost: 'Call Cost',
    congratulations: 'Congratulations!',
    emailVerified:
      'You have successfully verified your account. You are all set and ready to use VoicerAI.',
    continue: 'Continue',
    ohNo: 'Oh no!',
    emailNotVerified: 'Something went wrong with the verification, please try again.',
    tryAgain: 'Try again',
    checkEmail: 'Check your email!',
    checkEmailDesc:
      'We sent a confirmation email to your email ID. Check your inbox and click on the confirmation link to continue.',
    cancelAnyTime: 'Cancel Anytime',
    totalAppointments: 'Total appointments',
    totalEmployee: 'Total Employees',
    ediAgent: 'Update Agent',
    resendEmail: 'Resend Verification Mail',
  },
}
export default english
