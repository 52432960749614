import {AxiosPromise} from 'axios'
import Api from '../service/api'
import {UploadMediaParams} from '../type/media'
import {ApiResponse} from '../type/api/auth'

export const uploadMedia = (body: UploadMediaParams): AxiosPromise<ApiResponse> => {
  return Api.post('/upload-media', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
