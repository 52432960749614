import React from 'react'
import {useTranslation} from 'react-i18next'
import Pagination from 'react-bootstrap/Pagination'
import {PaginationComponentProps} from './pagination.props'
import './pagination.css'

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  totalPage,
  handlePageClick,
  active,
  setActive,
}) => {
  const {t} = useTranslation()
  const items = []

  items.push(
    <button
      className="border-0 bg-transparent"
      onClick={() => handlePageChange(active - 1)}
      type="button"
      aria-label={t('previous')}
    >
      <p className="next-prev-text m-0 pe-3">{t('previous')}</p>
    </button>,
  )

  items.push(
    <Pagination.Item key={active} active>
      {active}
    </Pagination.Item>,
  )

  if (active < totalPage) {
    items.push(
      <Pagination.Item key={active + 1} onClick={() => handlePageChange(active + 1)}>
        {active + 1}
      </Pagination.Item>,
    )
  }

  items.push(
    <button
      className="border-0 bg-transparent"
      onClick={() => handlePageChange(active + 1)}
      type="button"
      aria-label={t('next')}
    >
      <p className="next-prev-text m-0 ps-3">{t('next')}</p>
    </button>,
  )

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPage) {
      handlePageClick(page)
      setActive(page)
    }
  }

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  )
}

export default PaginationComponent
