import React, {useState} from 'react'
import {Form, Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import '../loginForm/loginForm.css'
import {Formik} from 'formik'
import Spacer from '../../atoms/spacer/Spacer'
import {resetPasswordValidationSchema} from '../../../utils/validations/validation'
import EyeOpen from '../../atoms/illustrations/EyeOpen'
import {ResetPasswordFormProps} from './resetpassword.props'
import LoadingButton from '../../atoms/button/Button'
import EyeClose from '../../atoms/illustrations/EyeClose'

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({handleResetPassword, loading}) => {
  const {t} = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordValidationSchema}
        onSubmit={(values, {setSubmitting}) => {
          handleResetPassword(values)
          setSubmitting(false)
        }}
      >
        {({handleSubmit, handleChange, values, touched, errors, isValid}) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicPassword">
              <p className="form-labels pb-1 m-0">{t('password')}</p>
              <div className="password-field-input">
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                  className="custom-input"
                />
                <Button
                  variant="link"
                  onClick={togglePasswordVisibility}
                  className={`eye-button ${
                    errors.password && touched.password ? 'error-occurred' : ''
                  }`}
                  aria-label={showPassword ? t('hidePassword') : t('showPassword')}
                >
                  {showPassword ? <EyeOpen /> : <EyeClose />}
                </Button>
                <Form.Control.Feedback type="invalid">
                  {`${t('password')} ${t(errors?.password || '')}`}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Spacer size={8} />
            <Form.Group controlId="formBasicConfirmPassword">
              <p className="form-labels pb-1 m-0">{t('confirmPassword')}</p>
              <div className="password-field-input">
                <Form.Control
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.confirmPassword}
                  className="custom-input"
                />
                <Button
                  variant="link"
                  onClick={toggleConfirmPasswordVisibility}
                  className={`eye-button ${
                    errors.confirmPassword && touched.confirmPassword ? 'error-occurred' : ''
                  }`}
                  aria-label={showConfirmPassword ? t('hidePassword') : t('showPassword')}
                >
                  {showConfirmPassword ? <EyeOpen /> : <EyeOpen />}
                </Button>
                <Form.Control.Feedback type="invalid">
                  {`${t('confirmPassword')} ${t(errors?.confirmPassword || '')}`}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <LoadingButton
              buttonText={t('resetPassword')}
              isSubmitting={loading}
              isValid={isValid}
            />
            <Spacer size={24} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResetPasswordForm
