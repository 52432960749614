export interface ApiResponse {
  status: boolean
  message: string
}

export interface Agent {
  id: string
  name: string
  slug: string
  greetingMessage: string
  personality: string | null
  agenda: string | null
  voicemailMessage: string | null
  about: string | null
  created_at: string
  updated_at: string
  deleted_at: string | null
  organizationId: number
  guidelines: Guideline[]
  roleId: number
}
interface Guideline {
  id: number
  guidelineType: string
  identifierKey: string
  identifierValue: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  agentId: number
}
export interface User {
  id: number | string
  name: string
  email: string
  roleId: number | string
  emailVerificationToken: string
  token: string
  organizationId: number | string
  slug: string
  profileImage: string
}
export interface signUpUserParams {
  name: string
  email: string
  phone?: string
  zipcode: string
  password: string
  password_confirmation: string
  age_terms_accepted: AcceptanceStatus
  country_code_alpha: string
}

export enum AcceptanceStatus {
  No = 0,
  Yes = 1,
}

export interface LoginParams {
  email: string
  password: string
}
export interface LoginResponse {
  data: LoginResponse
  message: string
  status: boolean
  id: number
  name: string
  email: string
  roleId: number
  email_verification_token: string
  token: string
  organization_id: number
  slug: string
  profile_image: string
}
