import React from 'react'

const EyeClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" fill="none" viewBox="0 0 18 17">
      <path
        fill="#2E2A40"
        fillRule="evenodd"
        d="M16.874 1.182a.692.692 0 10-.979-.98l-14.77 14.77a.692.692 0 00.98.98l2.628-2.629c1.26.594 2.714.985 4.267.985 2.477 0 4.699-.995 6.296-2.22.8-.614 1.46-1.3 1.928-1.972.453-.654.776-1.372.776-2.04 0-.666-.323-1.384-.776-2.038-.467-.673-1.128-1.358-1.928-1.972a10.994 10.994 0 00-.768-.538l2.346-2.346zm-3.351 3.351l-1.635 1.635a3.461 3.461 0 01-4.797 4.797l-1.308 1.308c.984.4 2.074.65 3.217.65 2.11 0 4.043-.852 5.453-1.934.703-.54 1.259-1.124 1.633-1.663.387-.557.53-.993.53-1.25 0-.255-.143-.691-.53-1.249-.374-.538-.93-1.123-1.633-1.662-.29-.222-.6-.434-.93-.632zM8.104 9.951a2.077 2.077 0 002.77-2.77l-2.77 2.77z"
        clipRule="evenodd"
      />
      <path
        fill="#2E2A40"
        d="M9 1.846c.953 0 1.868.147 2.724.398a.224.224 0 01.093.376l-.762.76a.234.234 0 01-.218.063A8.19 8.19 0 009 3.23c-2.11 0-4.043.852-5.453 1.934-.703.54-1.26 1.124-1.633 1.662-.387.558-.53.994-.53 1.25s.143.692.53 1.25c.327.47.792.975 1.373 1.455.107.089.117.25.02.348l-.655.655a.227.227 0 01-.307.015 8.786 8.786 0 01-1.569-1.684C.322 9.462 0 8.744 0 8.077s.322-1.385.776-2.039c.467-.673 1.128-1.358 1.928-1.972C4.301 2.84 6.522 1.846 9 1.846z"
      />
      <path
        fill="#2E2A40"
        d="M9 4.615c.11 0 .218.006.324.015.182.017.248.235.119.364l-1.12 1.119A2.083 2.083 0 007.037 7.4L5.916 8.52c-.128.129-.346.063-.363-.119A3.462 3.462 0 019 4.615z"
      />
    </svg>
  )
}

export default EyeClose
