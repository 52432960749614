import React, {useEffect, useMemo, useState} from 'react'
import {Form, Container, Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useParams} from 'react-router-dom'
import Spacer from '../../atoms/spacer/Spacer'
import {addAgentValidationSchema} from '../../../utils/validations/validation'
import Close from '../../atoms/illustrations/Close'
import LoadingButton from '../../atoms/button/Button'
import AddButton from '../../atoms/illustrations/AddButton'
import '../loginForm/loginForm.css'
import './addAgentForm.css'
import Remove from '../../atoms/illustrations/Remove'
import {
  AddAgentFormProps,
  Guideline,
  MiscellaneousType,
  QualifyQuestions,
  RulesData,
} from './addAgentForm.props'
import {RootState} from '../../../store/slices'
import {useSelector} from '../../../store'
import {GuidelineType} from '../../../type/api/agent'

const AddAgentForm: React.FC<AddAgentFormProps> = ({handleAddAgent, loading}) => {
  const {t} = useTranslation()
  const {id} = useParams()
  const agentList = useSelector((state: RootState) => state.agent?.agentList)
  const [addedValues, setAddedValues] = useState<Guideline[]>([])
  let [agentData] = useState<any>(agentList?.[0])
  const [qualifyQuestions, setQualifyQuestions] = useState<QualifyQuestions[]>([])
  const [miscellaneousData, setMiscellaneous] = useState<MiscellaneousType[]>([])
  const [rules, setRules] = useState<RulesData[]>([])
  const [qualifyError, setQualifyError] = useState('')
  const [questionError, setQuestionError] = useState('')
  const [miscellaneousError, setMiscellaneousError] = useState('')
  const [rulesError, setRulesError] = useState('')
  const initialValues = {
    agentName: agentData?.name,
    greetingMessage: agentData?.greetingMessage,
    personality: agentData?.personality,
    rules: '',
    rulesKey: '',
    agenda: agentData?.agenda,
    voicemailMessage: agentData?.voicemailMessage,
    companyInfo: agentData?.about || '',
    questionGuideline: '',
    questionGuidelineKey: '',
    qualifyGuidelineKey: '',
    qualifyGuideline: '',
    miscellaneous: '',
    miscellaneousKey: '',
  }

  useEffect(() => {
    agentData = agentList?.filter((item) => item.slug === id)?.[0]
    setRules(
      agentData?.guidelines
        ?.filter((item: any) => item.guidelineType === GuidelineType.RULES)
        ?.map((item: any) => {
          return {
            rulesKey: item?.identifierKey,
            rules: item?.identifierValue,
          }
        }) || [],
    )
    setMiscellaneous(
      agentData?.guidelines
        ?.filter((item: any) => item.guidelineType === GuidelineType.Miscellaneous)
        ?.map((item: any) => {
          return {
            miscellaneousKey: item?.identifierKey,
            miscellaneous: item?.identifierValue,
          }
        }) || [],
    )
    setQualifyQuestions(
      agentData?.guidelines
        ?.filter((item: any) => item.guidelineType === GuidelineType.Faq)
        ?.map((item: any) => {
          return {
            questionGuidelineKey: item?.identifierKey,
            questionGuideline: item?.identifierValue,
          }
        }) || [],
    )
    setAddedValues(
      agentData?.guidelines
        ?.filter((item: any) => item.guidelineType === GuidelineType.Guidelines)
        ?.map((item: any) => {
          return {
            qualifyGuidelineKey: item?.identifierKey,
            qualifyGuideline: item?.identifierValue,
          }
        }) || [],
    )
  }, [])
  const handleAdd = (values: any, setFieldValue?: any) => {
    const newValue = {
      qualifyGuideline: values.qualifyGuideline,
      qualifyGuidelineKey: values.qualifyGuidelineKey,
    }
    if (!newValue.qualifyGuideline || !newValue.qualifyGuidelineKey) {
      setQualifyError(t('qualifyError'))
    } else {
      setAddedValues([...addedValues, newValue])
      setFieldValue?.('qualifyGuideline', '')
      setFieldValue?.('qualifyGuidelineKey', '')
      setQualifyError('')
    }
  }
  const handleAddRule = (values: any, setFieldValue?: any) => {
    const newValue = {
      rulesKey: values.rulesKey,
      rules: values.rules,
    }
    if (!newValue.rules || !newValue.rulesKey) {
      setRulesError(t('rulesError'))
    } else {
      setRules([...rules, newValue])
      setFieldValue?.('rulesKey', '')
      setFieldValue?.('rules', '')
      setRulesError('')
    }
  }
  const handleAddQuestions = (values: any, setFieldValue?: any) => {
    const newValue = {
      questionGuideline: values.questionGuideline,
      questionGuidelineKey: values.questionGuidelineKey,
    }
    if (!newValue.questionGuideline || !newValue.questionGuidelineKey) {
      setQuestionError(t('questionError'))
    } else {
      setQualifyQuestions([...qualifyQuestions, newValue])
      setFieldValue?.('questionGuidelineKey', '')
      setFieldValue?.('questionGuideline', '')
      setQuestionError('')
    }
  }
  const handleMiscellaneousAdd = (value: any, setFieldValue?: any) => {
    const newValue = {
      miscellaneous: value.miscellaneous,
      miscellaneousKey: value.miscellaneousKey,
    }
    if (!newValue.miscellaneousKey || !newValue.miscellaneous) {
      setMiscellaneousError(t('miscellaneousError'))
    } else {
      setMiscellaneous([...miscellaneousData, newValue])
      setFieldValue?.('miscellaneousKey', '')
      setFieldValue?.('miscellaneous', '')
      setMiscellaneousError('')
    }
  }

  const handleMiscellaneousRemove = (index: number) => {
    const newValues = [...miscellaneousData]
    newValues.splice(index, 1)
    setMiscellaneous(newValues)
  }

  const handleRemove = (index: number) => {
    const newValues = [...addedValues]
    newValues.splice(index, 1)
    setAddedValues(newValues)
  }
  const handleRemoveRules = (index: number) => {
    const newValues = [...rules]
    newValues.splice(index, 1)
    setRules(newValues)
  }
  const handleRemoveQuestions = (index: number) => {
    const newValues = [...qualifyQuestions]
    newValues.splice(index, 1)
    setQualifyQuestions(newValues)
  }
  const combinedAddAgentValidationSchema = useMemo(() => {
    return Yup.object().shape({
      ...addAgentValidationSchema,
      questionGuideline:
        addedValues.length > 0
          ? Yup.string().notRequired()
          : Yup.string().required('Question Guideline is required'),
      questionGuidelineKey:
        addedValues.length > 0
          ? Yup.string().notRequired()
          : Yup.string().required('Question Guideline is required'),
      qualifyGuideline:
        addedValues.length > 0
          ? Yup.string().notRequired()
          : Yup.string().required('Qualify Guideline is required'),
      qualifyGuidelineKey:
        addedValues.length > 0
          ? Yup.string().notRequired()
          : Yup.string().required('Qualify Guideline is required'),
      miscellaneous:
        miscellaneousData.length > 0
          ? Yup.string().notRequired()
          : Yup.string().required('isRequired'),
      miscellaneousKey:
        miscellaneousData.length > 0
          ? Yup.string().notRequired()
          : Yup.string().required('isRequired'),
      rules: rules.length > 0 ? Yup.string().notRequired() : Yup.string().required('isRequired'),
      rulesKey: rules.length > 0 ? Yup.string().notRequired() : Yup.string().required('isRequired'),
    })
  }, [addedValues.length, miscellaneousData.length, rules.length, qualifyQuestions.length])

  const FormGroupWithClear = ({
    label,
    name,
    value,
    onChange,
    errors,
    touched,
    setFieldValue,
    placeholder,
    extraClass,
  }: any) => (
    <Form.Group className="w-100">
      <div className="password-field-input">
        <p className="form-labels pb-1 m-0">{t(label)}</p>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={!!errors[name] && touched[name]}
          className="custom-input"
          placeholder={placeholder}
        />
        {value && value.length > 0 && (
          <Button
            variant="link"
            onClick={() => setFieldValue(name, '')}
            className={`cancel-button ${extraClass} `}
          >
            <Close />
          </Button>
        )}
        <Form.Control.Feedback type="invalid">
          {`${t(name)} ${t(errors[name] || '')}`}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  )

  return (
    <Container fluid className="pb-3">
      <Spacer size={16} />
      <div className="add-agent-form">
        <Formik
          initialValues={initialValues}
          validationSchema={combinedAddAgentValidationSchema}
          onSubmit={(values) => {
            handleAddAgent(values, addedValues, miscellaneousData, rules, qualifyQuestions)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                <FormGroupWithClear
                  label="agentName"
                  name="agentName"
                  value={values.agentName}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('agentName')}
                />
              </div>
              <Spacer size={16} />
              <Form.Group>
                <p className="form-labels pb-1 m-0">{t('greetingMessage')}</p>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="greetingMessage"
                  value={values.greetingMessage}
                  onChange={(e) => {
                    handleChange(e)
                    setFieldValue('greetingMessage', e.target.value)
                    setFieldTouched('greetingMessage', true)
                  }}
                  isInvalid={
                    !!errors.greetingMessage || typeof touched.greetingMessage === 'object'
                  }
                  className="custom-input-text-area"
                  placeholder={t('greetingMessage')}
                />
                <Form.Control.Feedback type="invalid">
                  {`${t('greetingMessage')}${
                    errors.greetingMessage ? ` ${t(errors.greetingMessage as string)}` : ''
                  }`}
                </Form.Control.Feedback>
              </Form.Group>

              <Spacer size={16} />
              <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                <FormGroupWithClear
                  label="personality"
                  name="personality"
                  value={values.personality}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('personality')}
                />
              </div>

              <Spacer size={16} />
              <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                <FormGroupWithClear
                  label="agenda"
                  name="agenda"
                  value={values.agenda}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('agenda')}
                />
              </div>
              <Spacer size={16} />

              <Form.Group>
                <p className="form-labels pb-1 m-0">{t('voicemailMessage')}</p>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="voicemailMessage"
                  value={values.voicemailMessage}
                  onChange={(e) => {
                    handleChange(e)
                    setFieldValue('voicemailMessage', e.target.value)
                    setFieldTouched('voicemailMessage', true)
                  }}
                  isInvalid={
                    !!errors.voicemailMessage || typeof touched.voicemailMessage === 'object'
                  }
                  className="custom-input-text-area"
                  placeholder={t('voicemailMessage')}
                />
                <Form.Control.Feedback type="invalid">
                  {`${t('greetingMessage')}${
                    errors.voicemailMessage ? ` ${t(errors.voicemailMessage as string)}` : ''
                  }`}
                </Form.Control.Feedback>
              </Form.Group>
              <Spacer size={16} />
              <Form.Group>
                <p className="form-labels pb-1 m-0">{t('companyInfo')}</p>
                <Form.Control
                  as="textarea"
                  rows={10}
                  name="companyInfo"
                  value={values.companyInfo}
                  onChange={(e) => {
                    handleChange(e)
                    setFieldValue('companyInfo', e.target.value)
                    setFieldTouched('companyInfo', true)
                  }}
                  isInvalid={!!errors.companyInfo || typeof touched.companyInfo === 'object'}
                  className="custom-input-text-area"
                  placeholder={t('companyInfo')}
                />
                <Form.Control.Feedback type="invalid">
                  {`${t('greetingMessage')}${
                    errors.companyInfo ? ` ${t(errors.companyInfo as string)}` : ''
                  }`}
                </Form.Control.Feedback>
              </Form.Group>
              <Spacer size={16} />
              <p className="form-labels pb-1 m-0">{t('rules')}</p>
              <div className="add-agent-forms-view d-flex flex-row justify-content-between gap-3">
                <FormGroupWithClear
                  name="rulesKey"
                  value={values.rulesKey}
                  onChange={(e: any) => {
                    handleChange(e)
                    setRulesError('')
                  }}
                  errors={!rulesError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('rulesKey')}
                  extraClass="cancel-top"
                />

                <FormGroupWithClear
                  name="rules"
                  value={values.rules}
                  onChange={(e: any) => {
                    handleChange(e)
                    setRulesError('')
                  }}
                  errors={!rulesError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('rules')}
                  extraClass="cancel-top"
                />
                <button
                  type="button"
                  aria-label={t('addGuideLine')}
                  className="border-0 bg-transparent"
                  onClick={() => handleAddRule(values, setFieldValue)}
                >
                  <AddButton height={32} width={32} />
                </button>
              </div>

              {rulesError && <p className="show-error p-0 m-0">{rulesError}</p>}
              <Spacer size={8} />
              <div className="added-values">
                {rules?.map((value, index) => (
                  <div
                    key={`${value}-${index + 2}`}
                    className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3 mt-3"
                  >
                    <Form.Group className="w-100">
                      <div className="password-field-input">
                        <Form.Control
                          type="text"
                          name="rulesKey"
                          value={value.rulesKey}
                          onChange={(e) => {
                            const newData = [...rules]
                            newData[index].rulesKey = e.target.value
                            setRules(newData)
                          }}
                          className="custom-input"
                        />
                        {value && value.rulesKey?.length > 0 && (
                          <Button
                            variant="link"
                            className="cancel-button-input"
                            onClick={() => {
                              const newData = [...rules]
                              newData[index].rulesKey = ''
                              setRules(newData)
                            }}
                          >
                            <Close />
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="w-100">
                      <div className="password-field-input">
                        <Form.Control
                          type="text"
                          name="rules"
                          value={value.rules}
                          onChange={(e) => {
                            const newData = [...rules]
                            newData[index].rules = e.target.value
                            setRules(newData)
                          }}
                          className="custom-input"
                        />
                        {value && value.rules?.length > 0 && (
                          <Button
                            variant="link"
                            className="cancel-button-input"
                            onClick={() => {
                              const newData = [...rules]
                              newData[index].rules = ''
                              setRules(newData)
                            }}
                          >
                            <Close />
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                    <button
                      type="button"
                      aria-label={t('removeGuideLine')}
                      className="border-0 bg-transparent"
                      onClick={() => handleRemoveRules(index)}
                    >
                      <Remove />
                    </button>
                  </div>
                ))}
              </div>

              <Spacer size={16} />
              <p className="form-labels pb-1 m-0">{t('guideline')}</p>
              <div className="add-agent-forms-view d-flex flex-row justify-content-between  gap-3">
                <FormGroupWithClear
                  name="qualifyGuidelineKey"
                  value={values.qualifyGuidelineKey}
                  onChange={(e: any) => {
                    handleChange(e)
                    setQualifyError('')
                  }}
                  errors={!qualifyError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('qualifyGuidelineKey')}
                  extraClass="cancel-top"
                />

                <FormGroupWithClear
                  name="qualifyGuideline"
                  value={values.qualifyGuideline}
                  onChange={(e: any) => {
                    handleChange(e)
                    setQualifyError('')
                  }}
                  errors={!qualifyError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('qualifyGuideline')}
                  extraClass="cancel-top"
                />
                <button
                  type="button"
                  aria-label={t('addGuideLine')}
                  className="border-0 bg-transparent mt-3"
                  onClick={() => handleAdd(values, setFieldValue)}
                >
                  <AddButton height={32} width={32} />
                </button>
              </div>

              {qualifyError && <p className="show-error p-0 m-0">{qualifyError}</p>}
              <Spacer size={16} />
              <div className="added-values">
                {addedValues?.map((value, index) => (
                  <div
                    key={`${value}-${index + 2}`}
                    className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3 mt-3"
                  >
                    <Form.Group className="w-100">
                      <div className="password-field-input">
                        <Form.Control
                          type="text"
                          name="qualifyGuidelineKey"
                          value={value.qualifyGuidelineKey}
                          onChange={(e) => {
                            const newData = [...addedValues]
                            newData[index].qualifyGuidelineKey = e.target.value
                            setAddedValues(newData)
                          }}
                          className="custom-input"
                        />
                        {value && value.qualifyGuidelineKey?.length > 0 && (
                          <Button
                            variant="link"
                            className="cancel-button-input"
                            onClick={() => {
                              const newData = [...addedValues]
                              newData[index].qualifyGuidelineKey = ''
                              setAddedValues(newData)
                            }}
                          >
                            <Close />
                          </Button>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group className="w-100">
                      <div className="password-field-input">
                        <Form.Control
                          type="text"
                          name="qualifyGuideline"
                          value={value.qualifyGuideline}
                          onChange={(e) => {
                            const newData = [...addedValues]
                            newData[index].qualifyGuideline = e.target.value
                            setAddedValues(newData)
                          }}
                          className="custom-input"
                        />
                        {value && value.qualifyGuideline?.length > 0 && (
                          <Button
                            variant="link"
                            className="cancel-button-input"
                            onClick={() => {
                              const newData = [...addedValues]
                              newData[index].qualifyGuideline = ''
                              setAddedValues(newData)
                            }}
                          >
                            <Close />
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                    <button
                      type="button"
                      aria-label={t('removeGuideLine')}
                      className="border-0 bg-transparent"
                      onClick={() => handleRemove(index)}
                    >
                      <Remove />
                    </button>
                  </div>
                ))}
              </div>
              <Spacer size={16} />
              <p className="form-labels pb-1 m-0">{t('miscellaneous')}</p>
              <div className="add-agent-forms-view d-flex flex-row justify-content-center align-items-center gap-3">
                <FormGroupWithClear
                  name="miscellaneousKey"
                  value={values.miscellaneousKey}
                  onChange={(e: any) => {
                    handleChange(e)
                    setMiscellaneousError('')
                  }}
                  errors={!miscellaneousError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('miscellaneousKey')}
                  extraClass="cancel-top"
                />
                <FormGroupWithClear
                  name="miscellaneous"
                  value={values.miscellaneous}
                  onChange={(e: any) => {
                    handleChange(e)
                    setMiscellaneousError('')
                  }}
                  errors={!miscellaneousError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('miscellaneous')}
                  extraClass="cancel-top"
                />
                <button
                  type="button"
                  aria-label={t('addGuideLine')}
                  className="border-0 bg-transparent mt-3"
                  onClick={() => handleMiscellaneousAdd(values, setFieldValue)}
                >
                  <AddButton height={32} width={32} />
                </button>
              </div>
              {miscellaneousError && <p className="show-error p-0 m-0">{miscellaneousError}</p>}
              <div>
                {miscellaneousData?.length > 0 &&
                  miscellaneousData?.map((value, index) => (
                    <div
                      key={`${value}-${index + 2}`}
                      className="add-agent-forms-view d-flex flex-row justify-content-center align-items-center gap-3 mt-3"
                    >
                      <Form.Group className="w-100">
                        <div className="password-field-input">
                          <Form.Control
                            type="text"
                            name="miscellaneousKey"
                            value={value.miscellaneousKey}
                            onChange={(e) => {
                              const newData = [...miscellaneousData]
                              newData[index].miscellaneousKey = e.target.value
                              setMiscellaneous(newData)
                            }}
                            className="custom-input"
                          />
                          {value && value.miscellaneousKey.length > 0 && (
                            <Button
                              variant="link"
                              className="cancel-button-input"
                              onClick={() => {
                                const newData = [...miscellaneousData]
                                newData[index].miscellaneousKey = ''
                                setMiscellaneous(newData)
                              }}
                            >
                              <Close />
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="w-100">
                        <div className="password-field-input">
                          <Form.Control
                            type="text"
                            name="miscellaneous"
                            value={value.miscellaneous}
                            onChange={(e) => {
                              const newData = [...miscellaneousData]
                              newData[index].miscellaneous = e.target.value
                              setMiscellaneous(newData)
                            }}
                            className="custom-input"
                          />
                          {value && value.miscellaneous.length > 0 && (
                            <Button
                              variant="link"
                              className="cancel-button-input"
                              onClick={() => {
                                const newData = [...miscellaneousData]
                                newData[index].miscellaneous = ''
                                setMiscellaneous(newData)
                              }}
                            >
                              <Close />
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                      <button
                        type="button"
                        aria-label={t('removeGuideLine')}
                        className="border-0 bg-transparent"
                        onClick={() => handleMiscellaneousRemove(index)}
                      >
                        <Remove />
                      </button>
                    </div>
                  ))}
              </div>
              <Spacer size={16} />
              <p className="form-labels pb-1 m-0">{t('faq')}</p>
              <div className="add-agent-forms-view d-flex flex-row justify-content-between gap-3">
                <FormGroupWithClear
                  name="questionGuidelineKey"
                  value={values.questionGuidelineKey}
                  onChange={(e: any) => {
                    handleChange(e)
                    setQuestionError('')
                  }}
                  errors={!questionError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('questionGuidelineKey')}
                  extraClass="cancel-top"
                />

                <FormGroupWithClear
                  name="questionGuideline"
                  value={values.questionGuideline}
                  onChange={(e: any) => {
                    handleChange(e)
                    setQuestionError('')
                  }}
                  errors={!questionError && errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('questionGuideline')}
                  extraClass="cancel-top"
                />
                <button
                  type="button"
                  aria-label={t('addGuideLine')}
                  className="border-0 bg-transparent"
                  onClick={() => handleAddQuestions(values, setFieldValue)}
                >
                  <AddButton height={32} width={32} />
                </button>
              </div>
              <Spacer size={8} />
              {questionError && <p className="show-error p-0 m-0">{questionError}</p>}
              <Spacer size={16} />
              <div className="added-values">
                {qualifyQuestions?.map((value, index) => (
                  <div
                    key={`${value}-${index + 2}`}
                    className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3 mt-3"
                  >
                    <Form.Group className="w-100">
                      <div className="password-field-input">
                        <Form.Control
                          type="text"
                          name="questionGuidelineKey"
                          value={value.questionGuidelineKey}
                          onChange={(e) => {
                            const newData = [...qualifyQuestions]
                            newData[index].questionGuidelineKey = e.target.value
                            setQualifyQuestions(newData)
                          }}
                          className="custom-input"
                        />
                        {value && value.questionGuidelineKey?.length > 0 && (
                          <Button
                            variant="link"
                            className="cancel-button-input"
                            onClick={() => {
                              const newData = [...qualifyQuestions]
                              newData[index].questionGuidelineKey = ''
                              setQualifyQuestions(newData)
                            }}
                          >
                            <Close />
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="w-100">
                      <div className="password-field-input">
                        <Form.Control
                          type="text"
                          name="questionGuideline"
                          value={value.questionGuideline}
                          onChange={(e) => {
                            const newData = [...qualifyQuestions]
                            newData[index].questionGuideline = e.target.value
                            setQualifyQuestions(newData)
                          }}
                          className="custom-input"
                        />
                        {value && value.questionGuideline?.length > 0 && (
                          <Button
                            variant="link"
                            className="cancel-button-input"
                            onClick={() => {
                              const newData = [...qualifyQuestions]
                              newData[index].questionGuideline = ''
                              setQualifyQuestions(newData)
                            }}
                          >
                            <Close />
                          </Button>
                        )}
                      </div>
                    </Form.Group>

                    <button
                      type="button"
                      aria-label={t('removeGuideLine')}
                      className="border-0 bg-transparent"
                      onClick={() => handleRemoveQuestions(index)}
                    >
                      <Remove />
                    </button>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <LoadingButton
                  buttonText={id ? t('ediAgent') : t('addAgent')}
                  isSubmitting={loading}
                  isValid={isValid}
                  isCurvedButton
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

export default AddAgentForm
