import * as Yup from 'yup'

export const signUpValidationSchema = {
  fullName: Yup.string().required('isRequired'),
  email: Yup.string().email('isInValid').required('isRequired'),
  phoneNumber: Yup.number()
    .nullable()
    .notRequired()
    .min(0)
    .test(
      'mustBeTenDigits',
      'mustBeTenDigits',
      (value) => typeof value === 'number' && !/[eE+-]/.test(value.toString()),
    )
    .required('isRequired'),
  password: Yup.string()
    .required('isRequired')
    .min(8, 'mustBeEightCharacters')
    .test('lowercase-format', 'mustContainLowercase', (value: string | undefined) => {
      if (!value) return true
      const lowercaseRegex = /(?=.*[a-z])/
      return lowercaseRegex.test(value)
    })
    .test('uppercase-format', 'mustContainUppercase', (value: string | undefined) => {
      if (!value) return true
      const uppercaseRegex = /(?=.*[A-Z])/
      return uppercaseRegex.test(value)
    })
    .test('number-format', 'mustContainNumber', (value: string | undefined) => {
      if (!value) return true
      const numberRegex = /(?=.*[0-9])/
      return numberRegex.test(value)
    }),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'passwordMatch')
    .required('isRequired'),
}
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('isRequired')
    .test('email-format', 'isInValid', (value: string | undefined) => {
      if (!value) return true
      return Yup.string().email().isValidSync(value)
    }),
  password: Yup.string()
    .required('isRequired')
    .min(8, 'mustBeEightCharacters')
    .test('lowercase-format', 'mustContainLowercase', (value: string | undefined) => {
      if (!value) return true
      const lowercaseRegex = /(?=.*[a-z])/
      return lowercaseRegex.test(value)
    })
    .test('uppercase-format', 'mustContainUppercase', (value: string | undefined) => {
      if (!value) return true
      const uppercaseRegex = /(?=.*[A-Z])/
      return uppercaseRegex.test(value)
    })
    .test('number-format', 'mustContainNumber', (value: string | undefined) => {
      if (!value) return true
      const numberRegex = /(?=.*[0-9])/
      return numberRegex.test(value)
    }),
})

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('isRequired')
    .test('email-format', 'isInValid', (value: string | undefined) => {
      if (!value) return true
      return Yup.string().email().isValidSync(value)
    }),
})

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('isRequired'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'passwordMatch')
    .required('isRequired'),
})
export const addAgentValidationSchema = {
  agentName: Yup.string().required('isRequired'),
}

export const editAgentDetailsValidationSchema = Yup.object().shape({
  agentName: Yup.string().required('isRequired'),
  organizationName: Yup.string().required('isRequired'),
  greetingMessage: Yup.string().required('isRequired'),
  voiceMailMsg: Yup.string().required('isRequired'),
})
export const callSettingsValidationSchema = Yup.object().shape({
  contactPerson: Yup.string().required('isRequired'),
  address: Yup.string().required('isRequired'),
  country: Yup.string().required('isRequired'),
  city: Yup.string().required('isRequired'),
  state: Yup.string().required('isRequired'),
  postalCode: Yup.string().required('isRequired'),
  email: Yup.string().email('isInValid').required('isRequired'),
  phoneNumber: Yup.string().required('isRequired'),
  alternatePhoneNumber: Yup.string().required('isRequired'),
})
export const AgentAppointmentFormFieldsSchema = Yup.object().shape({
  slotAvailability: Yup.string().required('isRequired'),
  slotsSeparator: Yup.string().required('isRequired'),
  slotsError: Yup.string().required('isRequired'),
  noSlotsAvailable: Yup.string().required('isRequired'),
  errorBookingAppointment: Yup.string().required('isRequired'),
  appointmentBooked: Yup.string().required('isRequired'),
  slotIndex: Yup.string().required('isRequired'),
})
export const editAgentVoiceMailValidationSchema = Yup.object().shape({
  voiceMailMsg: Yup.string().required('isRequired'),
})
export const addEmployeeValidationSchema = Yup.object({
  email: Yup.string().email('isInValid').required('isRequired'),
  fullName: Yup.string().required('isRequired'),
  phone: Yup.string().required('isRequired'),
  timeSlot: Yup.string().required('isRequired'),
  password: Yup.string()
    .required('isRequired')
    .min(8, 'mustBeEightCharacters')
    .test('lowercase-format', 'mustContainLowercase', (value: string | undefined) => {
      if (!value) return true
      const lowercaseRegex = /(?=.*[a-z])/
      return lowercaseRegex.test(value)
    })
    .test('uppercase-format', 'mustContainUppercase', (value: string | undefined) => {
      if (!value) return true
      const uppercaseRegex = /(?=.*[A-Z])/
      return uppercaseRegex.test(value)
    })
    .test('number-format', 'mustContainNumber', (value: string | undefined) => {
      if (!value) return true
      const numberRegex = /(?=.*[0-9])/
      return numberRegex.test(value)
    }),
})
export const updateEmployeeValidationSchema = Yup.object({
  email: Yup.string().email('isInValid').required('isRequired'),
  fullName: Yup.string().required('isRequired'),
  phone: Yup.string().required('isRequired'),
  timeSlot: Yup.string().required('isRequired'),
})
export const ediProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('isRequired'),
  email: Yup.string()
    .required('isRequired')
    .test('email-format', 'isInValid', (value: string | undefined) => {
      if (!value) return true
      return Yup.string().email().isValidSync(value)
    }),
  phoneNumber: Yup.string().required('isRequired'),
  address: Yup.string().required('isRequired'),
  city: Yup.string().required('isRequired'),
  postalCode: Yup.string().required('isRequired'),
})
