import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {formatAgents} from '../../utils/redux/auth'
import {AgentListResponse} from '../../type/api/agent'
import {agentListAction} from '../action/agent'
import {logoutUserAction} from '../action/auth'
import {DeactivateAccountAction} from '../action/profile'

export interface AgentState {
  agentList: any[]
}
const initialState: AgentState = {
  agentList: [],
}

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      agentListAction.fulfilled,
      (state, action: PayloadAction<AgentListResponse>) => {
        state.agentList = formatAgents(action.payload.data.data)
      },
    )
    builder.addCase(logoutUserAction.fulfilled, (state) => {
      state.agentList = initialState.agentList
    })
    builder.addCase(DeactivateAccountAction.fulfilled, (state) => {
      state.agentList = initialState.agentList
    })
  },
})
