import React from 'react'
import Table from 'react-bootstrap/Table'
import {useTranslation} from 'react-i18next'
import Trash from '../../atoms/illustrations/Trash'
import Edit from '../../atoms/illustrations/Edit'
import PaginationComponent from '../../molecules/pagination/Pagination'
import {TableComponentProps} from './tableComponent.props'
import './tableComponent.css'
import Document from '../../atoms/illustrations/Document'

const TableComponent: React.FC<TableComponentProps> = ({
  active,
  setActive,
  data,
  handlePageChange,
  tableHeader,
  totalPages,
  isCallHistory,
}) => {
  const {t} = useTranslation()
  return (
    <div className="table-view">
      <Table>
        <thead className="table-head-view border-bottom">
          {tableHeader.map((item, index) => (
            <th className="table-title ps-2" key={`${item} - ${index + 2}`}>
              {item}
            </th>
          ))}
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr className="table-data" key={`${item} - ${index + 2}`}>
              <td className="tale-user-view d-flex flex-row justify-content-start align-items-center gap-2 pt-3 pb-3 border-0">
                <img
                  src={
                    item.image ||
                    'https://cdn.pixabay.com/photo/2017/11/10/05/46/group-2935521_1280.png'
                  }
                  className="image-view"
                  alt="profile"
                />
                <p className="name-text m-0">{item.name}</p>
              </td>
              <td className="pt-3 pb-3 border-0">
                <p className="activity-text ">{isCallHistory ? item.time : item.activity}</p>
              </td>
              <td className="pt-3 pb-3 border-0">
                <p className="activity-text">{isCallHistory ? item.duration : item.time}</p>
              </td>
              {isCallHistory && (
                <td className="pt-3 pb-3 border-0">
                  <p className="activity-text">{item.callCost}</p>
                </td>
              )}
              <td className="pt-3 pb-3 border-0">
                <div className="d-flex flex-row justify-content-start-align-items-center gap-3 mt-2 table-actions-view">
                  <button type="button" aria-label="Delete" className="border-0 bg-transparent">
                    <Trash />
                  </button>
                  {isCallHistory && (
                    <button type="button" aria-label="Delete" className="border-0 bg-transparent">
                      <Document />
                    </button>
                  )}
                  <button type="button" aria-label="Edit" className="border-0 bg-transparent">
                    <Edit />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination-view d-flex flex-row justify-content-between align-items-center ps-4 pe-4">
        <p className="pagination-text">
          {`${t('showing')} ${active} ${t('of')} ${totalPages} ${t('entries')}`}
        </p>
        <PaginationComponent
          active={active}
          setActive={setActive}
          handlePageClick={(page) => {
            handlePageChange(page)
          }}
          totalPage={totalPages}
        />
      </div>
    </div>
  )
}

export default TableComponent
