import React from 'react'
import {ReactNotifications} from 'react-notifications-component'
import Router from '../../routes'
import 'react-notifications-component/dist/theme.css'
import AppTemplate from '../template/appTemplate/AppTemplate'
import {useSelector} from '../../store'
import {RootState} from '../../store/slices'

const Main = () => {
  const token = useSelector((state: RootState) => state.auth.user?.token)
  return (
    <>
      <ReactNotifications />
      <section>
        {token ? (
          <AppTemplate>
            <Router />
          </AppTemplate>
        ) : (
          <Router />
        )}
      </section>
    </>
  )
}
export default Main
