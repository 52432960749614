import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Pagination from 'react-bootstrap/Pagination'
import '../accountActivityScreen/accountActivityScreen.css'
import TableComponent from '../../organism/table/TableComponent'
import {callHistoryTableData} from '../../../utils/constants/constant'

const CallSettingsScreen = () => {
  const [active, setActive] = useState(1)
  const {t} = useTranslation()
  const items: any = []

  for (let number = 1; number <= 5; number += 1) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={(index: any) => console.log(index.target, 'index')}
      >
        {number}
      </Pagination.Item>,
    )
  }
  return (
    <div className="account-activity-screen">
      <p className="account-activity-text">{t('callHistory')}</p>
      <TableComponent
        active={active}
        setActive={setActive}
        handlePageChange={(page) => setActive(page)}
        tableHeader={[t('name'), t('time'), t('duration'), t('callCost'), t('action')]}
        data={callHistoryTableData}
        totalPages={12}
        isCallHistory
      />
    </div>
  )
}

export default CallSettingsScreen
