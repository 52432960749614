import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Form, Container, Button, Dropdown, Spinner} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Close from '../../atoms/illustrations/Close'
import {
  addEmployeeValidationSchema,
  updateEmployeeValidationSchema,
} from '../../../utils/validations/validation'
import Spacer from '../../atoms/spacer/Spacer'
import AddButton from '../../atoms/illustrations/AddButton'
import Back from '../../atoms/illustrations/Back'
import LoadingButton from '../../atoms/button/Button'
import DemoProfile from '../../atoms/illustrations/DemoProfile'
import './addEmployeeFrom.css'
import {useAppDispatch, useSelector} from '../../../store'
import {RootState} from '../../../store/slices'
import {getCountryListAction} from '../../../store/action/general'
import {handleAddEmployeeProps} from './addEmployee.props'
import EyeClose from '../../atoms/illustrations/EyeClose'
import EyeOpen from '../../atoms/illustrations/EyeOpen'
import {MediaUserType} from '../../../type/media'
import {uploadMediaAction} from '../../../store/action/media'
import showNotification from '../../../function/notification'
import {getEmployeeListAction} from '../../../store/action/employee'
import Loader from '../../atoms/Loader'

const AddEmployeeForm: React.FC<handleAddEmployeeProps> = ({
  handleAddEmployee,
  loading,
  setErrors,
  errorText,
}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const countryList = useSelector((state: RootState) => state.general.countryList)
  const employeeData = useSelector((state: RootState) => state.employee.employeeList.employeeList)
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)
  const [employeeLeaveError, setEmployeeLeaveError] = useState('')
  const [startDate, setStartDate] = useState<Date | null | undefined>(null)
  const [endDate, setEndDate] = useState<Date | null | undefined>(null)
  const [filesData, setFilesData] = useState<any>([])
  const [dateRanges, setDateRanges] = useState<{startDate: Date; endDate: Date}[]>(
    employeeData?.[0]?.leaves || [],
  )
  const [dateError, setDateError] = useState('')
  const [diallingCodeList, setDiallingCodeList] = useState(countryList)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [showPassword, setShowPassword] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const [employeeLoading, setEmployeeLoading] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const addFile = async () => {
    const formData: any = new FormData()
    formData.append('media', filesData?.[0])
    formData.append('type', MediaUserType.EMPLOYEE)
    try {
      setFileLoading(true)
      const response = await dispatch(uploadMediaAction(formData)).unwrap()
      showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
      await dispatch(getEmployeeListAction({page: 1}))
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setFileLoading(false)
  }
  const getEmployeeList = async () => {
    setEmployeeLoading(true)
    try {
      await dispatch(getEmployeeListAction({page: 1}))
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setEmployeeLoading(false)
  }
  const handleDrop = (event: any) => {
    event.preventDefault()
    const {files} = event.dataTransfer
    if (files.length > 0) {
      setFilesData([...files])
    }
  }
  const handleUploadFile = (event: any) => {
    const {files} = event.target
    if (files && files.length > 0) {
      setFilesData([...files])
    }
  }
  useEffect(() => {
    if (filesData.length > 0) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result) {
          setPreviewImage(reader.result as string)
        }
      }
      reader.readAsDataURL(filesData[0])
      addFile()
    }
  }, [filesData])
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date)
    setDateError('')
  }

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date)
  }
  const handleDragOver = (event: any) => {
    event.preventDefault()
  }
  const getCountryList = async () => {
    if (countryList.length <= 0) {
      await dispatch(getCountryListAction()).unwrap()
    }
  }
  useEffect(() => {
    getCountryList()
    getEmployeeList()
  }, [])
  const handleAddDateRange = () => {
    if (startDate && endDate) {
      const newDateRange = {startDate, endDate}
      setDateRanges([...dateRanges, newDateRange])

      setStartDate(null)
      setEndDate(null)
    } else {
      setDateError(t('dateError'))
    }
  }
  const initialValues = {
    email: employeeData?.[0]?.email,
    password: '',
    fullName: employeeData?.[0]?.name,
    country:
      countryList?.filter((item) => item.id === employeeData?.[0]?.countryId)?.[0] ||
      countryList?.[0],
    phone: employeeData?.[0]?.phone,
    address: employeeData?.[0]?.address,
    city: employeeData?.[0]?.city,
    zipCode: employeeData?.[0]?.zipcode,
    organization: employeeData?.[0]?.organizationId,
    timeSlot: employeeData?.[0]?.timeSlot,
    about: employeeData?.[0]?.about,
    countryCodeAlpha:
      countryList?.filter((item) => item.id === employeeData?.[0]?.countryId)?.[0] ||
      countryList?.[0],
    fromDate: '',
    toDate: '',
  }

  const FormGroupWithClear = ({
    label,
    name,
    value,
    onChange,
    errors,
    touched,
    setFieldValue,
    placeholder,
  }: any) => (
    <Form.Group className="w-100">
      <div className="password-field-input">
        <p className="form-labels pb-1 m-0">{t(label)}</p>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={!!errors[name] && touched[name]}
          className="custom-input"
          placeholder={placeholder}
        />
        {value && value.length > 0 && (
          <Button variant="link" onClick={() => setFieldValue(name, '')} className="cancel-button">
            <Close />
          </Button>
        )}
        <Form.Control.Feedback type="invalid">
          {`${t(label)} ${t(errors[name] || '')}`}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  )
  const DatePickerWithClear = ({
    label,
    name,
    value,
    onChange,
    errors,
    touched,
    setFieldValue,
    placeholder,
    timeSlot,
  }: any) => (
    <Form.Group className="w-100">
      <div className="password-field-input w-100">
        <p className="form-labels pb-1 m-0">{label}</p>
        <DatePicker
          selected={value}
          onChange={(date: any) => onChange(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={timeSlot || 15}
          dateFormat="dd/MM/yyyy HH:mm"
          className="date-picker custom-input w-100 border-0"
          placeholderText={placeholder}
        />

        {value && (
          <Button variant="link" onClick={() => setFieldValue()} className="cancel-button">
            <Close />
          </Button>
        )}
        {errors[name] && touched[name] && (
          <Form.Control.Feedback type="invalid">{`${label} ${errors[name]}`}</Form.Control.Feedback>
        )}
      </div>
    </Form.Group>
  )
  return (
    <Container fluid className="pb-3">
      <button
        type="button"
        className="border-0 bg-transparent ps-2 pt-3"
        aria-label={t('backLabel')}
        onClick={() => navigate(-1)}
      >
        <Back />
      </button>
      <Spacer size={16} />
      {employeeLoading ? (
        <Loader />
      ) : (
        <div className="add-agent-form">
          <Formik
            initialValues={initialValues}
            validationSchema={
              employeeData?.[0]?.slug ? updateEmployeeValidationSchema : addEmployeeValidationSchema
            }
            onSubmit={(values, {setSubmitting}) => {
              handleAddEmployee(values, dateRanges, startDate, endDate)

              setSubmitting(false)
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              isValid,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroupWithClear
                  label="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('email')}
                />
                <Spacer size={16} />
                {!employeeData?.[0]?.slug && (
                  <Form.Group controlId="formBasicPassword">
                    <p className="form-labels pb-1 m-0">{t('password')}</p>
                    <div className="password-field-input">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder={`${t('password')}`}
                        value={values.password}
                        onChange={(e) => {
                          handleChange(e)
                          setErrors('')
                        }}
                        isInvalid={!!errors.password && touched.password}
                        className={`custom-input ${errorText ? 'error' : ''}`}
                      />
                      <Button
                        variant="link"
                        onClick={togglePasswordVisibility}
                        className={`eye-button ${
                          errors.password && touched.password ? 'error-occurred' : ''
                        }`}
                        aria-label={showPassword ? t('hidePassword') : t('showPassword')}
                      >
                        {showPassword ? <EyeOpen /> : <EyeClose />}
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        {`${t('password')} ${t(errors?.password || '')}`}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                )}
                <Spacer size={16} />
                <FormGroupWithClear
                  label="fullName"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  placeholder={t('fullName')}
                />
                <Spacer size={16} />
                <Dropdown>
                  <p className="form-labels pb-1 m-0">{t('countryCode')}</p>
                  <Dropdown.Toggle
                    id="add-employee-dropdown"
                    className="custom-input  border-0 gap-2 d-flex pb-0 mb-0 align-items-center w-100"
                  >
                    <img src={values.country?.flag} alt={`"flag"`} height={20} width={20} />
                    <p className="p-0 m-0 text-dark"> {values.country?.name} </p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100 dropdown-view">
                    {diallingCodeList.map(
                      (
                        option: {
                          id: any
                          flag: string | undefined
                          dialCode: string
                          name: string
                        },
                        index: number,
                      ) => (
                        <Dropdown.Item
                          key={`${option.id} - ${index + 1}`}
                          onClick={() => setFieldValue('country', option)}
                          className="description-text"
                        >
                          <div className="d-flex align-items-center gap-3">
                            <img src={option.flag} alt={`"flag"`} height={20} width={20} />
                            <p className="p-0 m-0 "> {option?.name} </p>
                          </div>
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Spacer size={16} />
                <Form.Group controlId="phoneNumber">
                  <div className="d-flex flex-row justify-content-between align-items-center gap-1">
                    <Dropdown>
                      <p className="form-labels pb-1 m-0">{t('countryCode')}</p>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="custom-input border-0 gap-2 d-flex pb-0 mb-0 align-items-center"
                      >
                        <img
                          src={values.countryCodeAlpha?.flag}
                          alt={`"flag"`}
                          height={20}
                          width={20}
                        />
                        <p className="p-0 m-0 text-dark"> +{values.countryCodeAlpha?.dialCode} </p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-view">
                        {diallingCodeList.map(
                          (
                            option: {
                              id: any
                              flag: string | undefined
                              dialCode: string
                            },
                            index: number,
                          ) => (
                            <Dropdown.Item
                              key={`${option.id} - ${index + 1}`}
                              onClick={() => setFieldValue('countryCodeAlpha', option)}
                              className="description-text"
                            >
                              <div className="d-flex align-items-center gap-3">
                                <img src={option.flag} alt={`"flag"`} height={20} width={20} />
                                <p className="p-0 m-0 "> +{option.dialCode} </p>
                              </div>
                            </Dropdown.Item>
                          ),
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="flex-grow-1">
                      <p className="form-labels pb-1 m-0">{t('phoneNumber')}</p>
                      <Form.Control
                        type="tel"
                        name="phone"
                        placeholder={`${t('phoneNumberEg')}`}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '')
                        }}
                        isInvalid={!!(touched.phone && errors.phone)}
                        className="custom-input"
                      />
                    </div>
                  </div>
                  {touched.phone && errors?.phone && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {`${t('phone')} ${t(errors?.phone || '')}`}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Spacer size={16} />
                <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                  <FormGroupWithClear
                    label="address"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    placeholder={t('address')}
                  />
                  <FormGroupWithClear
                    label="city"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    placeholder={t('city')}
                  />
                  <FormGroupWithClear
                    label="zipCode"
                    name="zipCode"
                    value={values.zipCode}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    placeholder={t('zipCode')}
                  />
                </div>
                <Spacer size={16} />
                <p className="form-labels pb-1 m-0">{t('profileImage')}</p>
                <div className="add-agent-forms-view d-flex flex-row justify-content-start align-items-center gap-3 w-100">
                  <div className="w-50">
                    <div
                      className="image-dropper-view d-flex flex-column justify-content-center align-items-center gap-2"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onClick={() => hiddenFileInput.current?.click()}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                          hiddenFileInput.current?.click()
                        }
                      }}
                      tabIndex={0}
                      role="button"
                    >
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleUploadFile}
                        style={{display: 'none'}}
                      />
                      {fileLoading ? (
                        <div className="add-employee-spinner-view d-flex justify-content-center">
                          <Spinner />
                        </div>
                      ) : employeeData?.[0]?.profileImage ? (
                        <img
                          src={employeeData?.[0]?.profileImage}
                          alt="Uploaded"
                          className="uploaded-image"
                          height={200}
                          width={200}
                        />
                      ) : (
                        <DemoProfile />
                      )}
                      <label htmlFor="fileInput" className="profile-image-desc-text m-0">
                        {t('dragToUpload')}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-100">
                    <div className="employee-time-slots">
                      <p className="form-labels pb-1 m-0">{t('timeSlot')}</p>
                      <Form.Control
                        type="tel"
                        name="timeSlot"
                        placeholder={`${t('timeSlot')}`}
                        value={values.timeSlot}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '')
                        }}
                        isInvalid={!!(touched.timeSlot && errors.timeSlot)}
                        className="custom-input"
                      />
                    </div>
                  </div>
                </div>
                <Spacer size={16} />
                <Form.Group>
                  <p className="form-labels pb-1 m-0">{t('about')}</p>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="about"
                    value={values.about}
                    onChange={(e) => {
                      handleChange(e)
                      setFieldValue('about', e.target.value)
                      setFieldTouched('about', true)
                    }}
                    isInvalid={!!errors.about && touched.about}
                    className="custom-input-text-area"
                    placeholder={t('about')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {`${t('about')} ${t(errors.about || '')}`}
                  </Form.Control.Feedback>
                </Form.Group>
                <Spacer size={16} />
                <div className="add-agent-forms-view d-flex flex-row justify-content-between align-items-center gap-3">
                  <p className="form-labels pb-1 m-0">{t('addEmployeeLeave')}</p>
                  <button
                    type="button"
                    aria-label={t('addGuideLine')}
                    className={`border-0 bg-transparent ${employeeLeaveError ? 'mt-1' : 'mt-3'}`}
                    onClick={() => handleAddDateRange()}
                  >
                    <AddButton height={32} width={32} />
                  </button>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center gap-3">
                  <DatePickerWithClear
                    label="Start Date"
                    name="startDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={() => setStartDate(null)}
                    placeholder={t('ddmmyy')}
                    timeSlot={values.timeSlot}
                  />

                  <DatePickerWithClear
                    label="End Date"
                    name="endDate"
                    value={endDate}
                    onChange={handleEndDateChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={() => setEndDate(null)}
                    placeholder={t('ddmmyy')}
                    timeSlot={values.timeSlot}
                  />
                </div>
                {dateError && <p className="show-error p-0 m-0">{dateError}</p>}
                {dateRanges.map((dateRange, index) => (
                  <div
                    key={`${index + 2}`}
                    className="d-flex flex-row justify-content-between align-items-center gap-3 mt-2"
                  >
                    <DatePickerWithClear
                      label="Start Date"
                      name={`${dateRange.startDate}`}
                      value={dateRange.startDate}
                      onChange={(date: any) => {
                        const newDateRanges = [...dateRanges]
                        newDateRanges[index].startDate = date
                        setDateRanges(newDateRanges)
                      }}
                      errors={errors}
                      touched={touched}
                      setFieldValue={(name: string, value: any) => {
                        const newDateRanges = [...dateRanges]
                        newDateRanges[index].startDate = value
                        setDateRanges(newDateRanges)
                      }}
                      placeholder={t('ddmmyy')}
                    />
                    <DatePickerWithClear
                      label="End Date"
                      name={`endDate-${index}`}
                      value={dateRange.endDate}
                      onChange={(date: any) => {
                        const newDateRanges = [...dateRanges]
                        newDateRanges[index].endDate = date
                        setDateRanges(newDateRanges)
                      }}
                      errors={errors}
                      touched={touched}
                      setFieldValue={(name: string, value: any) => {
                        const newDateRanges = [...dateRanges]
                        newDateRanges[index].endDate = value
                        setDateRanges(newDateRanges)
                      }}
                      placeholder={t('ddmmyy')}
                    />
                  </div>
                ))}
                <Spacer size={16} />
                <div className="d-flex justify-content-center align-items-center">
                  <LoadingButton
                    buttonText={t('save')}
                    isSubmitting={loading}
                    isCurvedButton
                    isValid
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Container>
  )
}

export default AddEmployeeForm
