import React from 'react'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import {ChartsProps} from './lineCharts.props'
import './lineChart.css'

const LineCharts: React.FC<ChartsProps> = ({data}) => {
  return (
    <ResponsiveContainer width="99%" height={500}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 0" />
        <XAxis dataKey="name" tickMargin={14} />
        <YAxis className="me-5" axisLine={false} tickMargin={20} />
        <Tooltip />
        <Line type="monotone" dataKey="pv" stroke="#2F80ED" activeDot={{r: 8}} />
        <Line type="monotone" dataKey="uv" stroke="#2F80ED" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineCharts
