import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import './accountActivityScreen.css'
import TableComponent from '../../organism/table/TableComponent'
import {useAppDispatch, useSelector} from '../../../store'
import {getAccountActivityAction} from '../../../store/action/profile'
import showNotification from '../../../function/notification'
import {RootState} from '../../../store/slices'
import Loader from '../../atoms/Loader'

const AccountActivityScreen = () => {
  const dispatch = useAppDispatch()
  const [active, setActive] = useState(1)
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const activityData = useSelector((state: RootState) => state.profile.activityList)
  const getAccountActivity = async (page: number) => {
    try {
      setLoading(true)
      await dispatch(getAccountActivityAction({page})).unwrap()
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }
  useEffect(() => {
    getAccountActivity(1)
  }, [])

  return (
    <div className="account-activity-screen">
      <p className="account-activity-text">{t('accountActivity')}</p>
      {loading ? (
        <Loader />
      ) : (
        <TableComponent
          active={active}
          setActive={setActive}
          handlePageChange={(page) => {
            getAccountActivity(page)
            setActive(page)
          }}
          tableHeader={[t('name'), t('activity'), t('dateAndTime'), t('action')]}
          data={activityData?.info?.map((item) => ({
            name: item?.name,
            activity: item?.description,
            image: item?.profileImage,
            time: item.updatedAt || item.createdAt,
          }))}
          totalPages={activityData?.lastPage}
        />
      )}
    </div>
  )
}

export default AccountActivityScreen
