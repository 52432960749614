import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import {Button, Modal, Spinner} from 'react-bootstrap'
import Spacer from '../../atoms/spacer/Spacer'
import logo from '../../../assets/images/logo.jpg'
import {DashboardCanvasProps} from './dashboardCanvas.props'
import Dashboard from '../../atoms/illustrations/Dashboard'
import Agent from '../../atoms/illustrations/Agent'
import Call from '../../atoms/illustrations/Call'
import Profile from '../../atoms/illustrations/Profile'
import Setting from '../../atoms/illustrations/Setting'
import Folder from '../../atoms/illustrations/Folder'
import UserOctant from '../../atoms/illustrations/UserOctant'
import Pricing from '../../atoms/illustrations/Pricing'
import Like from '../../atoms/illustrations/Like'
import ProfileDelete from '../../atoms/illustrations/ProfileDelete'
import {useAppDispatch, useSelector} from '../../../store'
import {RootState} from '../../../store/slices'
import {onUserLogin} from '../../../store/action'
import Employee from '../../atoms/illustrations/Employee'
import showNotification from '../../../function/notification'
import {DeactivateAccountAction} from '../../../store/action/profile'

const DashboardCanvas: React.FC<DashboardCanvasProps> = ({active, setActive, handleClose}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const userData = useSelector((state: RootState) => state.auth.user)
  const [deactivateModal, setDeactivateModal] = useState(false)
  const [deactivateLoading, setDeactivateLoading] = useState(false)
  const dashboardCanvas = [
    {
      title: t('dashboard'),
      icon: <Dashboard color={active === t('dashboard') ? '#fff' : undefined} />,
      redirection: '/dashboard',
    },
    {
      title: t('agent'),
      icon: <Agent color={active === t('agent') ? '#fff' : undefined} />,
      redirection: '/agent-settings',
    },
    {
      title: t('myAppointments'),
      icon: <Profile color={active === t('myAppointments') ? '#fff' : undefined} />,
      redirection: '/my-appointment',
    },
    {
      title: t('callHistory'),
      icon: <Call color={active === t('callHistory') ? '#fff' : undefined} />,
      redirection: '/call-history',
    },
    {
      title: t('employee'),
      icon: <Employee color={active === t('employee') ? '#fff' : undefined} />,
      redirection: '/add-employee',
    },
  ]
  const settingsCanvas = [
    {
      title: t('callSettings'),
      icon: <Setting color={active === t('callSettings') ? '#fff' : undefined} />,
      redirection: '/call-settings',
    },
    {
      title: t('appointmentsSetting'),
      icon: <Folder color={active === t('appointmentsSetting') ? '#fff' : undefined} />,
      redirection: '/appointment-settings',
    },
    {
      title: t('accountActivity'),
      icon: <UserOctant color={active === t('accountActivity') ? '#fff' : undefined} />,
      redirection: '/account-activity',
    },
  ]
  const othersCanvas = [
    {
      title: t('pricing'),
      icon: <Pricing color={active === t('pricing') ? '#fff' : undefined} />,
      redirection: '/pricing',
    },
    {
      title: t('help'),
      icon: <Like color={active === t('help') ? '#fff' : undefined} />,
      redirection: '/help',
    },
  ]
  const pathTitleMapping = {
    dashboard: t('dashboard'),
    'agent-settings': t('agent'),
    'my-appointment': t('myAppointments'),
    'call-history': t('callHistory'),
    'call-settings': t('callSettings'),
    'appointment-settings': t('appointmentsSetting'),
    'account-activity': t('accountActivity'),
    'add-employee': t('employee'),
    pricing: t('pricing'),
    help: t('help'),
    'add-agent': t('agent'),
  }

  useEffect(() => {
    const path = location.pathname.split('/')[1]
    if (pathTitleMapping[path as keyof typeof pathTitleMapping]) {
      setActive(pathTitleMapping[path as keyof typeof pathTitleMapping])
    } else {
      setActive('')
    }
  }, [location, t])
  useEffect(() => {
    if (!userData?.token) {
      navigate('/login')
    }
    const setTextUser = async () => {
      const user = {
        id: userData.id,
        name: userData.name,
        email: userData.email,
        token: userData.token,
      }
      await dispatch(onUserLogin(user))
    }
    setTextUser()
  }, [])

  const renderButton = (item: {title: string; icon: any; redirection: string}, index: number) => {
    return (
      <button
        type="button"
        key={`${item.title} - ${index + 1}`}
        className={`d-flex flex-row justify-content-start align-items-center border-0 p-3 w-100 m-1 ${
          active === item.title ? 'active' : 'bg-transparent'
        }`}
        onClick={() => {
          setActive(item.title)
          navigate(item.redirection)
          handleClose()
        }}
      >
        {item.icon}
        <p
          className={`${active === item.title ? 'active-title' : 'offcanvas-title'} m-0 text-start`}
        >
          {item.title}
        </p>
      </button>
    )
  }
  const handleDeactivateAccount = async () => {
    try {
      setDeactivateLoading(true)
      const response = await dispatch(DeactivateAccountAction()).unwrap()
      showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setDeactivateLoading(false)
    setDeactivateModal(false)
    navigate('/login')
  }

  return (
    <>
      <div className="w-100 mt-4 ps-3 pe-2">
        <button
          className="border-0 bg-transparent"
          onClick={() => {
            navigate('/dashboard')
            setActive(t('dashboard'))
          }}
          type="button"
        >
          <img src={logo} height={20} width={100} alt="logo" className="ms-2" />
        </button>
        <Spacer size={40} />
        <div className="canvas-container w-100">
          {dashboardCanvas.map((item, index) => (
            <React.Fragment key={`${item.title}-${index + 1}`}>
              {renderButton(item, index)}
            </React.Fragment>
          ))}
        </div>
        <Spacer size={8} />
        <div className="canvas-container w-100">
          <Spacer size={24} />
          <p className="offcanvas-heading-text m-0">{t('settings')}</p>
          {settingsCanvas.map((item, index) => (
            <React.Fragment key={`${item.title}-${index + 1}`}>
              {renderButton(item, index)}
            </React.Fragment>
          ))}
        </div>
        <Spacer size={8} />
        <div className="canvas-container w-100">
          <Spacer size={24} />
          <p className="offcanvas-heading-text  m-0">{t('others')}</p>
          {othersCanvas.map((item, index) => (
            <React.Fragment key={`${item.title}-${index + 1}`}>
              {renderButton(item, index)}
            </React.Fragment>
          ))}
        </div>
      </div>

      <Button
        onClick={() => setDeactivateModal(true)}
        className="bg-transparent border-0 d-flex flex-row gap-3 justify-content-start align-items-center flex-1 ms-4 ps-2 w-100 mb-5 p-3"
      >
        <ProfileDelete />
        <p className="deactivate-account-text p-0 mt-1 mb-0">{t('deactivateAccount')}</p>
      </Button>

      <Modal show={deactivateModal} onHide={() => setDeactivateModal(false)} centered>
        <div className="p-5 d-flex flex-column">
          <p className="modal-heading m-0">{t('areYouSure')}</p>
          <p className="modal-description">{t('deactivateDesc')}</p>
          <button
            type="button"
            className="remove-btn border-0 rounded-3 p-3"
            onClick={handleDeactivateAccount}
          >
            <div className="d-flex flex-row justify-content-between align-items-center">
              {deactivateLoading ? (
                <Spinner animation="border" size="sm" role="status" style={{color: 'white'}} />
              ) : (
                <>
                  <ProfileDelete />
                  <p className="p-0 m-0 remove-btn-text w-100"> {t('deactivateAccount')}</p>
                </>
              )}
            </div>
          </button>
          <Spacer size={8} />
          <button
            type="submit"
            className="next-btn  rounded-3 p-3"
            onClick={() => setDeactivateModal(false)}
          >
            <p className="p-0 m-0 next-btn-text"> {t('goBack')}</p>
          </button>
        </div>
      </Modal>
    </>
  )
}

export default DashboardCanvas
