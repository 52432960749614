import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {
  DeactivateAccountAction,
  getAccountActivityAction,
  getProfileDataAction,
} from '../action/profile'
import {
  ActivityResponse,
  ActivityState,
  GetProfileResponse,
  ProfileType,
} from '../../type/api/profile'
import {formatActivityList, getProfileData} from '../../utils/redux/profile'
import {logoutUserAction} from '../action/auth'

export interface profileState {
  profileInfo: ProfileType
  activityList: ActivityState
}

const initialState: profileState = {
  profileInfo: {
    name: '',
    email: '',
    address: '',
    city: '',
    zipcode: '',
    phone: '',
    profileImage: '',
  },
  activityList: {
    currentPage: 0,
    lastPage: 0,
    perPage: 0,
    info: [],
  },
}
export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getProfileDataAction.fulfilled,
      (state, action: PayloadAction<GetProfileResponse>) => {
        state.profileInfo = getProfileData(action.payload.data)
      },
    )

    builder.addCase(
      getAccountActivityAction.fulfilled,
      (state, action: PayloadAction<ActivityResponse>) => {
        state.activityList.currentPage = action.payload.data.current_page
        state.activityList.lastPage = action?.payload?.data?.last_page
        state.activityList.perPage = action.payload.data.per_page
        state.activityList.info = formatActivityList(action.payload.data.data)
      },
    )
    builder.addCase(logoutUserAction.fulfilled, (state) => {
      state.profileInfo = initialState.profileInfo
      state.activityList = initialState.activityList
    })

    builder.addCase(DeactivateAccountAction.fulfilled, (state) => {
      state.profileInfo = initialState.profileInfo
      state.activityList = initialState.activityList
    })
  },
})
