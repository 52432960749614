import React from 'react'

const Previous = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" fill="none" viewBox="0 0 12 22">
      <path
        fill="#ABABAB"
        d="M.316 10.41l9.73-9.725a1.096 1.096 0 011.546 0 1.09 1.09 0 010 1.543l-8.957 8.954 8.956 8.954a1.091 1.091 0 010 1.545 1.096 1.096 0 01-1.547 0L.315 11.955a1.102 1.102 0 01.001-1.544z"
      />
    </svg>
  )
}

export default Previous
