import {createAsyncThunk} from '@reduxjs/toolkit'
import {withToastForError} from '../../utils/redux/thunk'
import {LoginParams, LoginResponse, ApiResponse} from '../../type/api/auth'
import {forgotPassword, login, logout, resendVerificationEmail, resetPassword} from '../../api/auth'
import {
  ForgotPasswordParams,
  LogoutParams,
  ResendVerificationEmailParams,
  resetPasswordParams,
} from '../../api/type'

export const loginUserAction = createAsyncThunk(
  'auth/loginUser',
  withToastForError<LoginParams, any>(async (data) => {
    const response = await login(data)
    return response?.data?.status ? response.data : response
  }),
)

export const forgotPasswordAction = createAsyncThunk(
  'auth/forgotPassword',
  withToastForError<ForgotPasswordParams, ApiResponse>(async (data) => {
    const response = await forgotPassword(data)
    return response.data
  }),
)

export const resetPasswordAction = createAsyncThunk(
  'auth/resetPassword',
  withToastForError<resetPasswordParams, ApiResponse>(async (data) => {
    const response = await resetPassword(data)
    return response.data
  }),
)

export const logoutUserAction = createAsyncThunk(
  'auth/logout',
  withToastForError<LogoutParams, ApiResponse>(async (data) => {
    const response = await logout(data)
    return response.data
  }),
)
export const resendVerificationAction = createAsyncThunk(
  'auth/resendVerification',
  withToastForError<ResendVerificationEmailParams, ApiResponse>(async (data) => {
    const response = await resendVerificationEmail(data)
    return response.data
  }),
)
