import {Formik} from 'formik'
import React, {useMemo, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Form, Container, Spinner} from 'react-bootstrap'
import {ediProfileValidationSchema} from '../../../utils/validations/validation'
import LoadingButton from '../../atoms/button/Button'
import '../loginForm/loginForm.css'
import Spacer from '../../atoms/spacer/Spacer'
import './editprofileForm.css'
import Info from '../../atoms/illustrations/Info'
import {EditProfileFormProps} from './editProfileForm.props'
import Pen from '../../atoms/illustrations/Pen'
import {useSelector} from '../../../store'
import {RootState} from '../../../store/slices'

const EditProfileForm: React.FC<EditProfileFormProps> = ({
  handleEditForm,
  loading,
  handleFileChange,
  fileLoading,
}) => {
  const {t} = useTranslation()
  const fileInputRef: any = useRef(null)
  const profileData = useSelector((state: RootState) => state.profile.profileInfo)
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const initialValues = useMemo(() => {
    return {
      firstName: profileData.name || '',
      lastName: '',
      email: profileData.email || '',
      phoneNumber: profileData.phone || '',
      address: profileData.address || '',
      city: profileData.city || '',
      postalCode: profileData.zipcode || '',
    }
  }, [profileData])

  const FormGroupWithFeedback = ({
    label,
    name,
    value,
    onChange,
    errors,
    touched,
    placeholder,
    disabled,
  }: any) => (
    <Form.Group className="w-100">
      <div className="password-field-input">
        <p className="form-labels pb-1 m-0">{t(label)}</p>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={!!errors[name] && touched[name]}
          className="custom-input"
          placeholder={placeholder}
          disabled={disabled}
        />

        <Form.Control.Feedback type="invalid">
          {`${t(label)} ${t(errors[name] || '')}`}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  )
  return (
    <Container fluid>
      <Formik
        initialValues={initialValues}
        validationSchema={ediProfileValidationSchema}
        onSubmit={(values) => handleEditForm(values)}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          isValid,
        }) => {
          return (
            <div className="edit-profile-view">
              <div className="user-info d-flex flex-row justify-content-start align-items-center gap-3 pb-3 mb-3">
                <p className="m-0 user-name">{profileData.name}</p>
                <Info />
              </div>
              <Form onSubmit={handleSubmit} className="edit-profile-form">
                <div className="edit-profile-forms-view d-flex flex-column justify-content-between h-100">
                  <div>
                    <div className="edit-profile-forms-view d-flex flex-row justify-content-between align-items-start gap-5">
                      <div className="input-file-view">
                        {fileLoading ? (
                          <Spinner />
                        ) : (
                          <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={(e) => handleFileChange(e)}
                            className="d-none"
                          />
                        )}
                        <button
                          className="edit-profile-btn  border-0"
                          aria-label="button"
                          type="button"
                          onClick={handleButtonClick}
                        >
                          <Pen />
                        </button>
                        <p className="user-name m-0">{t('photoProfile')}</p>
                        <Spacer size={4} />
                        <div className="profile-upload-image">
                          <img
                            src={
                              profileData?.profileImage ||
                              'https://media.istockphoto.com/id/1298261537/vector/blank-man-profile-head-icon-placeholder.jpg?s=612x612&w=0&k=20&c=CeT1RVWZzQDay4t54ookMaFsdi7ZHVFg2Y5v7hxigCA='
                            }
                            className="profile-upload-image"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="edit-profile-forms-view w-100 d-flex flex-row justify-content-between align-items-center gap-4">
                          <div className="w-100">
                            <FormGroupWithFeedback
                              label={t('firstName')}
                              name="firstName"
                              type="text"
                              value={values.firstName}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              placeholder={t('firstName')}
                            />
                          </div>
                        </div>
                        <Spacer size={16} />
                        <div className="edit-profile-forms-view d-flex flex-row justify-content-center align-items-center gap-4">
                          <FormGroupWithFeedback
                            label={t('email')}
                            name="email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            placeholder={t('email')}
                            disabled
                          />
                          <div className="w-100">
                            <p className="form-labels pb-1 m-0">{t('phoneNumber')}</p>
                            <Form.Control
                              type="tel"
                              name="phoneNumber"
                              placeholder={`${t('phoneNumberEg')}`}
                              value={values.phoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onInput={(e: React.FormEvent<HTMLInputElement>) => {
                                e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '')
                              }}
                              isInvalid={!!(touched.phoneNumber && errors.phoneNumber)}
                              className="custom-input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Spacer size={16} />
                    <FormGroupWithFeedback
                      label={t('address')}
                      name="address"
                      type="text"
                      value={values.address}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      placeholder={t('address')}
                    />
                    <Spacer size={16} />
                    <div className="edit-profile-forms-view d-flex flex-row justify-content-center align-items-center gap-4">
                      <div className="w-100">
                        <FormGroupWithFeedback
                          label={t('city')}
                          name="city"
                          type="text"
                          value={values.city}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          placeholder={t('city')}
                        />
                      </div>
                      <div className="w-100">
                        <FormGroupWithFeedback
                          label={t('postal')}
                          name="postalCode"
                          type="text"
                          value={values.postalCode}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          placeholder={t('postal')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center w-100 save-btn-view">
                    <LoadingButton
                      buttonText={t('save')}
                      isSubmitting={loading}
                      isValid={isValid}
                      isCurvedButton
                    />
                  </div>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    </Container>
  )
}

export default EditProfileForm
