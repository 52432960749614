import React, {useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import AuthTemplate from '../../template/authTemplate/AuthTemplate'
import {useAppDispatch} from '../../../store'
import ResetPasswordForm from '../../organism/resetpasswordForm/ResetPasswordForm'
import {authInfoData} from '../../../utils/constants/constant'
import background from '../../../assets/images/background.png'
import {resetPasswordAction} from '../../../store/action/auth'
import {resetPasswordFormFields} from '../../organism/resetpasswordForm/resetpassword.props'
import showNotification from '../../../function/notification'

const ResetPasswordScreen = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [route] = useSearchParams()
  const resetEmail = route.get('email') as string
  const token = route.get('token') as string
  const [loading, setLoading] = useState(false)
  const handleResetPassword = async (values: resetPasswordFormFields) => {
    try {
      setLoading(true)
      const response = await dispatch(
        resetPasswordAction({
          email: resetEmail,
          password: values.password,
          password_confirmation: values.confirmPassword,
          verify_token: token,
        }),
      ).unwrap()
      showNotification({title: t('success'), type: 'success', message: `${response?.message}`})
      navigate('/login')
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }
  return (
    <>
      <AuthTemplate
        title={t('resetPassword')}
        description={t('resetPasswordDesc')}
        FormComponent={
          <ResetPasswordForm
            handleResetPassword={(values: resetPasswordFormFields) => handleResetPassword(values)}
            loading={loading}
          />
        }
        authInfo={authInfoData}
        image={background}
      />
    </>
  )
}

export default ResetPasswordScreen
