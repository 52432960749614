import React from 'react'
import {useTranslation} from 'react-i18next'
import AgentAppointmentSettingForm from '../../organism/agentAppointmentSettingForm/AgentAppointmentSettingForm'

const AppointmentSettingScreen = () => {
  const {t} = useTranslation()
  return (
    <div>
      <p className="call-setting-text ps-3 pt-3 m-0">{t('settings')}</p>
      <AgentAppointmentSettingForm />
    </div>
  )
}

export default AppointmentSettingScreen
