import React, {Suspense, useEffect} from 'react'
import {Provider as ReduxProvider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {getToken, onMessage} from 'firebase/messaging'
import store, {persistor} from './store'
import './languages/i18n'
import Main from './components/screens'
import configs from './config'
import Loader from './components/atoms/Loader'
import {messaging} from './firebase/config'
import showNotification from './function/notification'
import './App.css'

Sentry.init({
  dsn: configs.sentryDSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
})

function App() {
  onMessage(messaging, (payload) => {
    showNotification({
      title: payload.notification?.title || '',
      message: payload.notification?.body || '',
      type: 'info',
    })
  })

  async function requestPermission() {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      await navigator.serviceWorker.register('firebase-messaging-sw.js')
      await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      })
    } else if (permission === 'denied') {
      alert('You denied for the notification')
    }
  }

  // useEffect(() => {
  //   requestPermission()
  // }, [])

  return (
    <div className="data-theme">
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </ReduxProvider>
    </div>
  )
}
export default App
