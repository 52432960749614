import {AgentList} from '../../type/api/agent'
import {LoginResponse, User} from '../../type/api/auth'

export const formatAuthUser = (responseUser: LoginResponse): User => {
  return {
    id: responseUser.id,
    email: responseUser.email,
    name: responseUser.name,
    emailVerificationToken: responseUser.email_verification_token,
    organizationId: responseUser.organization_id,
    roleId: responseUser.roleId,
    slug: responseUser.slug,
    token: responseUser.token,
    profileImage: responseUser?.profile_image,
  }
}

export const formatAgents = (agentResponse: AgentList[]) => {
  return agentResponse.map((item) => {
    return {
      id: item.id,
      name: item.name,
      slug: item.slug,
      greetingMessage: item.greeting_message,
      personality: item.personality,
      agenda: item.agenda,
      voicemailMessage: item.voicemail_message,
      about: item.about,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      deletedAt: item.deleted_at,
      organizationId: item.organization_id,
      guidelines: item.guidelines.map((guideline) => {
        return {
          id: guideline.id,
          identifierKey: guideline.identifier_key,
          identifierValue: guideline.identifier_value,
          guidelineType: guideline.guideline_type,
          createdAt: guideline.created_at,
          updatedAt: guideline.updated_at,
          deletedAt: guideline.deleted_at,
          agentId: guideline.agent_id,
        }
      }),
      roleId: item.role_id,
    }
  })
}
