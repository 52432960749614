import React from 'react'

const VideoCall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <rect
        width="21.424"
        height="21.424"
        x="1.241"
        y="5.287"
        stroke="#fff"
        strokeWidth="2.4"
        rx="6"
      />
      <path
        stroke="#fff"
        strokeWidth="2.4"
        d="M22.711 16.157V14.47a2 2 0 01.872-1.652l4.06-2.774c1.328-.907 3.129.043 3.129 1.65v8.61c0 1.609-1.801 2.56-3.128 1.652l-4.061-2.774a2 2 0 01-.872-1.652v-1.373z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2.4"
        d="M8.836 16h6.234M11.953 19.117v-6.234"
      />
    </svg>
  )
}

export default VideoCall
