import {AxiosPromise} from 'axios'
import Api from '../service/api'
import {
  ActivityParams,
  ActivityResponse,
  GetProfileResponse,
  UpdateProfileType,
} from '../type/api/profile'
import {ApiResponse} from '../type/api/auth'

export const getProfileData = (): AxiosPromise<GetProfileResponse> => {
  return Api.get('/get-profile')
}

export const updateProfile = (body: UpdateProfileType): AxiosPromise<ApiResponse> => {
  return Api.post('/update-profile', body)
}

export const deactivateAccount = (): AxiosPromise<ApiResponse> => {
  return Api.post('/deactivate-account')
}
export const getAccountActivity = (body: ActivityParams): AxiosPromise<ActivityResponse> => {
  return Api.get('/audit-log-list', {params: body})
}
