import React, {useState} from 'react'
import {Carousel} from 'react-bootstrap'
import './authInfo.css'
import {AuthInfoProps} from './authInfo.props'
import Spacer from '../../atoms/spacer/Spacer'
import logo from '../../../assets/images/logo.jpg'

const AuthInfo: React.FC<AuthInfoProps> = ({authInfo}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex)
  }
  return (
    <>
      <div className="auth-info-container d-flex justify-content-center align-items-end w-100 h-100">
        <div className="image-container">
          <img src={authInfo[activeIndex]?.image} alt="person" className="image" />
        </div>

        <Carousel
          controls={false}
          className="carousel-container"
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {authInfo.map((info, index) => (
            <Carousel.Item key={`${info.title}-${index + 1}`} className="info-item">
              <div className="info-container text-center">
                <p className="header-text p-0 m-0">{info.label}</p>
                <Spacer size={4} />
                <img src={logo} height={20} width={100} alt="logo" />
                <Spacer size={6} />
                <p className="description-carousel-text ps-3 pe-3 m-0">{info.description}</p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default AuthInfo
