import React from 'react'
import {useTranslation} from 'react-i18next'
import AgentCallSettingForm from '../../organism/agentCallSettingForm/AgentCallSettingForm'
import './agentCallSettingScreen.css'

const AgentCallSettingScreen = () => {
  const {t} = useTranslation()
  return (
    <>
      <p className="call-setting-text ps-3 pt-3 m-0">{t('callSettings')}</p>
      <AgentCallSettingForm handleChangeCallSettings={(values) => console.log(values)} />
    </>
  )
}

export default AgentCallSettingScreen
