import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import CallInfoCard from '../../molecules/authInfo/callInfoCard/CallInfoCard'
import {useAppDispatch, useSelector} from '../../../store'
import Spacer from '../../atoms/spacer/Spacer'
import './dashboard.css'
import LineCharts from '../../organism/charts/lineCharts/LineChart'
import {RootState} from '../../../store/slices'
import {onUserLogin} from '../../../store/action'
import Calender from '../../atoms/illustrations/Calender'
import LeftIcon from '../../atoms/illustrations/LeftIcon'
import {getDashboardDataAction} from '../../../store/action/general'
import showNotification from '../../../function/notification'
import Loader from '../../atoms/Loader'

const DashboardScreen = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const userData = useSelector((state: RootState) => state.auth.user)
  const [startDateState, setStartDateState] = useState(moment().subtract(1, 'day').toDate())
  const [endDateState, setEndDateState] = useState(new Date())
  const jobCallData = useSelector((state: RootState) => state.general.dashboardAppointmentList)
  const minDate = moment(startDateState).subtract(1, 'year').toDate()
  const maxDate = moment(startDateState).add(1, 'year').toDate()
  const [loading, setLoading] = useState(false)
  const [chartsData, setChartsData] = useState([])

  const formatDate = (date: Date) => moment(date).format('MMM Do YYYY')
  const setToken = async () => {
    const user = {
      id: userData.id,
      name: userData.name,
      email: userData.email,
      token: userData.token,
    }
    await dispatch(onUserLogin(user))
  }
  const getDashboardInfo = async () => {
    try {
      setLoading(true)
      await dispatch(
        getDashboardDataAction({
          from_date: moment(startDateState).format('YYYY-MM-DD'),
          to_date: moment(endDateState).format('YYYY-MM-DD'),
        }),
      )
    } catch (error) {
      showNotification({title: t('error'), type: 'danger', message: `${error}`})
    }
    setLoading(false)
  }

  const getMonthAndDayLists = () => {
    const monthList: any = []
    const dayList: any = []
    const weekList: any = []

    const response = jobCallData?.appointments

    Object.keys(response).forEach((key) => {
      if (key.length === 7 && key.match(/^\d{4}-\d{2}$/)) {
        monthList.push(key)
      } else if (key.length === 10 && key.match(/^\d{4}-\d{2}-\d{2}$/)) {
        dayList.push(key)
      } else if (key.length === 8 && key.match(/^\d{4}-\d{2}-\d{2}$/)) {
        weekList.push(key)
      }
    })

    const monthNames = monthList
      .map((monthKey: any) => {
        const date = moment(monthKey, 'YYYY-MM')
        return {
          name: date.isValid() ? date.format('MMMM') : 'Invalid Date',
          uv: response?.[monthKey],
          amt: response?.[monthKey],
        }
      })
      .filter((item: any) => item.name !== 'Invalid Date')

    const dayNames = dayList
      .map((dayKey: any) => {
        const date = moment(dayKey, 'YYYY-MM-DD')
        return {
          name: date.isValid() ? date.format('dddd') : 'Invalid Date',
          uv: response?.[dayKey],
          amt: response?.[dayKey],
        }
      })
      .filter((item: any) => item.name !== 'Invalid Date')

    const weekNames = weekList
      .map((weekKey: any) => {
        const date = moment(weekKey, 'YYYY-MM-DD')
        const weekNumber = date.isValid() ? date.week() : 'Invalid Date'
        return {name: date.isValid() ? `Week ${weekNumber}` : 'Invalid Date', uv: response[weekKey]}
      })
      .filter((item: any) => item.name !== 'Invalid Date')

    if (startDateState && endDateState) {
      const startMoment = moment(startDateState, 'YYYY-MM-DD')
      const endMoment = moment(endDateState, 'YYYY-MM-DD')
      const diffDays = endMoment.diff(startMoment, 'days')

      if (diffDays > 7 && diffDays <= 30) {
        const weeksArray = generateWeeksArray(startMoment, endMoment, jobCallData.appointments)
        return {monthNames, dayNames, weekNames: weeksArray}
      }
    }

    return {
      monthNames,
      dayNames,
      weekNames,
    }
  }

  useEffect(() => {
    const newData = getMonthAndDayLists()
    if (newData?.weekNames?.length > 0) {
      setChartsData(newData?.weekNames)
    } else if (newData?.dayNames?.length > 0) {
      setChartsData(newData?.dayNames)
    } else if (newData?.monthNames?.length > 0) {
      setChartsData(newData?.monthNames)
    }
  }, [jobCallData])

  const generateWeeksArray = (startDate: moment.Moment, endDate: moment.Moment, response: any) => {
    const weeksArray: any = []

    let currentWeekStart = startDate.clone().startOf('isoWeek')
    const end = endDate.clone().endOf('isoWeek')

    while (currentWeekStart.isSameOrBefore(end)) {
      const weekNumber = currentWeekStart.week()
      const weekKey = currentWeekStart.format('YYYY-MM-DD')
      const uvValue = response[weekKey] !== undefined ? response[weekKey] : 0
      weeksArray.push({name: `Week ${weekNumber}`, uv: uvValue, amt: uvValue})
      currentWeekStart = currentWeekStart.add(1, 'weeks')
    }

    return weeksArray
  }

  useEffect(() => {
    setToken()
  }, [])

  useEffect(() => {
    getDashboardInfo()
  }, [startDateState, endDateState])

  const CustomDateInput = ({value, onClick}: any) => (
    <button
      className="form-control border-0"
      onClick={onClick}
      type="button"
      aria-label="date-button"
    >
      <div className="d-flex flex-row justify-content-between align-items-center gap-2">
        <p className="date-text pt-0 m-0">{formatDate(value)}</p>
        <Calender />
      </div>
    </button>
  )

  const jobCallsList = [
    {
      title: t('totalAgents'),
      numberOfCalls: jobCallData.agentsCount || 0,
    },
    {
      title: t('totalAppointments'),
      numberOfCalls: jobCallData.appointmentsCount || 0,
    },
    {
      title: t('totalEmployee'),
      numberOfCalls: jobCallData.employeesCount || 0,
    },
  ]
  console.log(chartsData, 'chartsData')
  return (
    <div className="dashboard-view">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
            <p className="dashboard-title p-0 m-0">{t('home')}</p>
            <div className="datepicker-view d-flex flex-row gap-3 align-items-center me-3">
              <DatePicker
                selected={startDateState}
                onChange={(date: Date) => setStartDateState(date)}
                customInput={<CustomDateInput value={formatDate(startDateState)} />}
                className="form-control p-0 m-0"
                minDate={minDate}
                maxDate={maxDate}
              />
              <LeftIcon />
              <DatePicker
                selected={endDateState}
                onChange={(date: Date) => setEndDateState(date)}
                customInput={<CustomDateInput value={formatDate(startDateState)} />}
                className="form-control p-0 m-0"
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </div>
          <Spacer size={8} />
          <div className="call-history-view d-flex flex-row justify-content-start align-items-center gap-3 flex-wrap">
            {jobCallsList.map((item) => (
              <CallInfoCard
                key={item.title}
                numberOfCalls={item.numberOfCalls}
                title={item.title}
              />
            ))}
          </div>
          <Spacer size={16} />
          {chartsData?.length > 0 && (
            <div className="charts-view d-flex flex-xxl-row flex-xl-row flex-lg-column flex-md-column flex-sm-column justify-content-between align-items-center gap-3">
              <div className="chart">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <p className="chart-title">{t('totalCallDetails')}</p>
                </div>
                <Spacer size={32} />
                <LineCharts data={chartsData} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default DashboardScreen
