import React from 'react'
import HelpCard from '../../molecules/helpCard/HelpCard'
import './helpScreen.css'
import {halpArray} from '../../../utils/constants/constant'

const HelpScreen = () => {
  return (
    <div className="help-screen-view d-flex flex-row flex-wrap">
      {halpArray.map((item) => (
        <HelpCard title={item.title} image={item.image} />
      ))}
    </div>
  )
}

export default HelpScreen
