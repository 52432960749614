import {AxiosPromise} from 'axios'
import {ApiResponse, LoginParams, LoginResponse, signUpUserParams} from '../type/api/auth'
import Api from '../service/api'
import {
  ForgotPasswordParams,
  LogoutParams,
  ResendVerificationEmailParams,
  VerityEmailParams,
  resetPasswordParams,
} from './type'

export const login = (body: LoginParams): AxiosPromise<LoginResponse> => Api.post(`/login`, body)

export const register = (body: signUpUserParams): AxiosPromise<ApiResponse> => {
  return Api.post('/register', body)
}

export const verifyEmail = (body: VerityEmailParams): AxiosPromise<ApiResponse> => {
  return Api.post('/verify-email-token', body)
}

export const forgotPassword = (body: ForgotPasswordParams): AxiosPromise<ApiResponse> => {
  return Api.post('/forgot-password', body)
}

export const resetPassword = (body: resetPasswordParams): AxiosPromise<ApiResponse> => {
  return Api.post('/reset-password', body)
}

export const logout = (body: LogoutParams): AxiosPromise<ApiResponse> => {
  return Api.post('/logout', body)
}
export const resendVerificationEmail = (
  body: ResendVerificationEmailParams,
): AxiosPromise<ApiResponse> => {
  return Api.post('/resend-verification-email', body)
}
