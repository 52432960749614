import React from 'react'

const ArrowLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="30" fill="none" viewBox="0 0 38 30">
      <path
        stroke="#2E2A40"
        strokeWidth="5"
        d="M16.667 2.167L3.833 15m0 0l12.834 12.833M3.833 15h33.834"
      />
    </svg>
  )
}

export default ArrowLeft
