import React from 'react'
import {CallInfoCardProps} from './callInfoCard.props'
import './callInfoCard.css'

const CallInfoCard: React.FC<CallInfoCardProps> = ({title, numberOfCalls}) => {
  return (
    <div className="call-info-card d-flex flex-row justify-content-between align-items-center">
      <p className="call-title p-0 m-0">{title}</p>
      <p className="number-of-calls p-0 m-0">{numberOfCalls}</p>
    </div>
  )
}

export default CallInfoCard
