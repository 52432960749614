import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import AuthTemplate from '../../../template/authTemplate/AuthTemplate'
import {authInfoData} from '../../../../utils/constants/constant'
import background from '../../../../assets/images/background.png'
import './verificationFailure.css'

const VerificationFailureScreen = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  return (
    <AuthTemplate
      title={t('ohNo')}
      description={t('emailNotVerified')}
      authInfo={authInfoData}
      image={background}
      noBack
      isVerification
      FormComponent={
        <button
          className="verification-failed-btn border-0 w-100"
          type="button"
          aria-label={t('continue')}
          onClick={() => navigate('/login')}
        >
          <p className="verification-failed-text m-0">{t('tryAgain')}</p>
        </button>
      }
    />
  )
}

export default VerificationFailureScreen
