import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {logoutUserAction} from '../action/auth'
import {DeactivateAccountAction} from '../action/profile'
import {EmployeeListResponse, EmployeeListSate} from '../../type/api/employee'
import {getEmployeeListAction} from '../action/employee'
import {formatEmployeeList} from '../../utils/redux/employee'

export interface EmployeeState {
  employeeList: {
    currentPage: number
    lastPage: number
    perPage: number
    employeeList: EmployeeListSate[]
  }
}

export const initialState: EmployeeState = {
  employeeList: {
    currentPage: 0,
    perPage: 0,
    lastPage: 0,
    employeeList: [],
  },
}
export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getEmployeeListAction.fulfilled,
      (state, action: PayloadAction<EmployeeListResponse>) => {
        console.log(action?.payload?.data, 'action payload data')
        state.employeeList.currentPage = action.payload?.data?.current_page
        state.employeeList.lastPage = action.payload?.data?.last_page
        state.employeeList.perPage = action.payload?.data?.per_page
        state.employeeList.employeeList = formatEmployeeList(action.payload?.data?.data)
      },
    )
  },
})
