import {createAsyncThunk} from '@reduxjs/toolkit'
import {withToastForError} from '../../utils/redux/thunk'
import {appointmentList} from '../../api/appointment'
import {AppointmentListParams, AppointmentListResponse} from '../../type/api/appointment'

export const appointmentListAction = createAsyncThunk(
  'appointment/appointment-list',
  withToastForError<AppointmentListParams, AppointmentListResponse>(async (data) => {
    const response = await appointmentList(data)
    return response.data
  }),
)
