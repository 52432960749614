import React, {memo} from 'react'
import Spinner from 'react-bootstrap/Spinner'

const Loader = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <Spinner animation="border" role="status" style={{width: '5rem', height: '5rem'}} />
  </div>
)

export default memo(Loader)
