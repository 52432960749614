import {CountryFormat, Plan} from '../store/action/types'

export function formatCountryList(data: CountryFormat[]) {
  return data.map((item) => ({
    flag: item.flag,
    id: item.id,
    dialCode: item.dialling_code,
    countryCodeAlpha: item.country_code_alpha,
    expression: item.postcode_regexp || '',
    name: item?.full_name,
  }))
}

export function formatPlanList(data: Plan[]) {
  return data.map((item) => ({
    id: item?.id,
    planCategoryId: item?.plan_category_id,
    currencyId: item?.currencies_id,
    name: item?.name,
    description: item.description,
    amount: item?.amount,
    currencyCode: item?.currencies?.currency_code_alpha,
    paymentLink: item?.payment_link,
  }))
}
