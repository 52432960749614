import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {appointmentListAction} from '../action/appointment'
import {AppointmentData, AppointmentListResponse} from '../../type/api/appointment'
import {setAppointmentData} from '../../utils/redux/appointment'
import {logoutUserAction} from '../action/auth'
import {DeactivateAccountAction} from '../action/profile'

export interface AppointmentState {
  appointmentList: AppointmentData[]
}

const initialState: AppointmentState = {
  appointmentList: [],
}

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      appointmentListAction.fulfilled,
      (state, action: PayloadAction<AppointmentListResponse>) => {
        state.appointmentList = setAppointmentData(action.payload.data)
      },
    )
    builder.addCase(logoutUserAction.fulfilled, (state) => {
      state.appointmentList = initialState.appointmentList
    })
    builder.addCase(DeactivateAccountAction.fulfilled, (state) => {
      state.appointmentList = initialState.appointmentList
    })
  },
})
