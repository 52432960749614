import React from 'react'
import {SVGProps} from './Dashboard'

const Employee: React.FC<SVGProps> = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" fill="none" viewBox="0 0 22 24">
      <path
        fill={color || '#757575'}
        fillRule="evenodd"
        d="M10.91 2.182a3.273 3.273 0 100 6.545 3.273 3.273 0 000-6.545zM5.454 5.455a5.455 5.455 0 1110.909 0 5.455 5.455 0 01-10.91 0zM0 22.91C0 16.883 4.884 12 10.91 12c6.024 0 10.908 4.884 10.908 10.91a1.09 1.09 0 11-2.182 0 8.727 8.727 0 00-17.454 0 1.09 1.09 0 11-2.182 0z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Employee
