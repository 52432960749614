import {AxiosPromise} from 'axios'
import Api from '../service/api'
import {
  EmployeeListParams,
  EmployeeListResponse,
  SaveEmployeeParams,
  UpdateEmployeeParams,
} from '../type/api/employee'
import {ApiResponse} from '../type/api/auth'

export const saveEmployee = (body: SaveEmployeeParams): AxiosPromise<ApiResponse> => {
  return Api.post('/save-employee', body)
}

export const getEmployeeList = (body: EmployeeListParams): AxiosPromise<EmployeeListResponse> => {
  return Api.get('/employee-list', {params: body})
}
export const updateEmployee = (body: UpdateEmployeeParams): AxiosPromise<ApiResponse> => {
  return Api.post('/update-employee', body)
}
