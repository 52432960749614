export interface AddAgentBody {
  name: string
  slug: string
  greeting_message?: string
  personality?: string
  agenda?: string
  voicemail_message?: string
  about?: string
  guidelines: any[]
}
export interface EditAgentBody {
  agent_slug: string
  name: string
  slug: string
  greeting_message?: string
  personality?: string
  agenda?: string
  voicemail_message?: string
  about?: string
  guidelines: any[]
}
export interface DeleteAgentBody {
  agent_slug: string
}

export interface AgentListBody {
  page?: number
}

export interface AgentListResponse {
  status: boolean
  message: string
  current_page: number
  data: {
    data: AgentList[]
  }
  last_page: number
  per_page: number
}

export interface AgentList {
  id: string
  name: string
  slug: string
  greeting_message: string
  personality: string
  agenda: string
  voicemail_message: string
  about: string
  created_at: string
  updated_at: string
  deleted_at: string
  organization_id: number
  guidelines: AgentGuidelineTypeResponse[]
  role_id: number
}

export interface AgentListGuidelineType {
  id: number
  guideline_type: GuidelineType
  identifier_key: string
  identifier_value: string
  created_at: string
  updated_at: string
  deleted_at: string
  agent_id: string
}

export interface AgentGuidelineTypeResponse {
  id: string
  guideline_type: GuidelineType
  identifier_key: string
  identifier_value: string
  created_at: string
  updated_at: string
  deleted_at: string
  agent_id: string
}

export interface AgentGuidelineType {
  guideline_type: GuidelineType
  identifier_key: string
  identifier_value: string
}

export enum GuidelineType {
  RULES = '1',
  Guidelines = '2',
  Miscellaneous = '3',
  Faq = '4',
}
