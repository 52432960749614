import {ActivityInfo, ActivityInfoState, ProfileResponse} from '../../type/api/profile'

export const getProfileData = (profileData: ProfileResponse) => {
  return {
    name: profileData.name,
    address: profileData.address,
    city: profileData.city,
    zipcode: profileData.zipcode,
    email: profileData.email,
    phone: profileData.phone,
    profileImage: profileData.profile_image,
  }
}
export const formatActivityList = (data: ActivityInfo[]) => {
  return data?.map((item) => ({
    id: item?.id,
    description: item?.description,
    userId: item?.user_id,
    name: item?.user_name,
    profileImage: item?.profile_image,
    createdAt: item?.created_at,
    updatedAt: item?.updated_at,
  }))
}
