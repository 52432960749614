import {setAuthorization} from '../../service/api'
import {UserStateType} from './types'
import {userActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../function/utils'

export const onUserLogin = (user: UserStateType) => async (dispatch: AppDispatch) => {
  try {
    await setAuthorization(user.token)
    await dispatch(userActions.updateUser({user}))
  } catch (e: any) {
    apiErrorBlock(e)
  }
}
