import React from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import Home from './components/screens/Home'
import LoginScreen from './components/screens/loginScreen'
import SignUpScreen from './components/screens/signUpScreen'
import EmailVerificationScreen from './components/screens/emailVerificationScreen'
import VerificationFailureScreen from './components/screens/emailVerificationScreen/verificationFailure'
import VerificationSuccessScreen from './components/screens/emailVerificationScreen/verificationSuccess'
import PageNotFound from './components/screens/PageNotFound'
import ForgotPasswordScreen from './components/screens/forgotPasswordScreen'
import ResetPasswordScreen from './components/screens/resetPasswordScreen'
import DashboardScreen from './components/screens/dashboard'
import AgentScreen from './components/screens/agentDashboard'
import AddAgentScreen from './components/screens/addAgent'
import PrivateRoute from './privateRoutes'
import AddEmployeeScreen from './components/screens/addEmployee/AddEmployee'
import MyAppointmentScreen from './components/screens/myAppointments'
import AgentCallSettingScreen from './components/screens/agentCallSettingScreen'
import AppointmentSettingScreen from './components/screens/appointmentSettingScreen'
import EditProfileScreen from './components/screens/editProfileScreen'
import ComingSoonScreen from './components/screens/comingSoon'
import PricingScreen from './components/screens/pricingScreen'
import AccountActivityScreen from './components/screens/accountActivityScreen'
import CallSettingsScreen from './components/screens/callHistoryScreen'
import CheckEmailScreen from './components/screens/checkEmailScreen'
import HelpScreen from './components/screens/helpScreen'

const Router = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/404" />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/signup" element={<SignUpScreen />} />
      <Route path="/verify-email" element={<EmailVerificationScreen />} />
      <Route path="/verify-email-success" element={<VerificationSuccessScreen />} />
      <Route path="/verify-email-failure" element={<VerificationFailureScreen />} />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/reset-password" element={<ResetPasswordScreen />} />
      <Route path="/check-email" element={<CheckEmailScreen />} />
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<DashboardScreen />} />
        <Route path="/agent-settings" element={<AgentScreen />} />
        <Route path="/coming-soon" element={<ComingSoonScreen />} />
        <Route path="/add-agent/:id?" element={<AddAgentScreen />} />
        <Route path="/add-employee" element={<AddEmployeeScreen />} />
        <Route path="/my-appointment" element={<MyAppointmentScreen />} />
        <Route path="/call-settings" element={<AgentCallSettingScreen />} />
        <Route path="/appointment-settings" element={<AppointmentSettingScreen />} />
        <Route path="/edit-profile" element={<EditProfileScreen />} />
        <Route path="/add-agent" element={<AddAgentScreen />} />
        <Route path="/pricing" element={<PricingScreen />} />
        <Route path="/account-activity" element={<AccountActivityScreen />} />
        <Route path="/call-history" element={<CallSettingsScreen />} />
        <Route path="/help" element={<HelpScreen />} />
      </Route>
    </Routes>
  )
}
export default Router
