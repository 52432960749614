import React from 'react'

const Next = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" fill="none" viewBox="0 0 12 22">
      <path
        fill="#ABABAB"
        d="M11.596 10.41L1.866.686a1.096 1.096 0 00-1.546 0 1.09 1.09 0 000 1.543l8.958 8.954L.32 20.136a1.091 1.091 0 000 1.545c.427.425 1.12.425 1.547 0l9.729-9.726c.42-.422.42-1.124-.001-1.544z"
      />
    </svg>
  )
}

export default Next
