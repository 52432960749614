import {createAsyncThunk} from '@reduxjs/toolkit'
import {withToastForError} from '../../utils/redux/thunk'
import {
  EmployeeListParams,
  EmployeeListResponse,
  SaveEmployeeParams,
  UpdateEmployeeParams,
} from '../../type/api/employee'
import {ApiResponse} from '../../type/api/auth'
import {getEmployeeList, saveEmployee, updateEmployee} from '../../api/employee'

export const saveEmployeeAction = createAsyncThunk(
  'employee/saveEmployee',
  withToastForError<SaveEmployeeParams, ApiResponse>(async (data) => {
    const response = await saveEmployee(data)
    return response.data
  }),
)
export const getEmployeeListAction = createAsyncThunk(
  'employee/employeeList',
  withToastForError<EmployeeListParams, EmployeeListResponse>(async (data) => {
    const response = await getEmployeeList(data)
    return response.data
  }),
)

export const updateEmployeeAction = createAsyncThunk(
  'employee/updateEmployee',
  withToastForError<UpdateEmployeeParams, ApiResponse>(async (data) => {
    const response = await updateEmployee(data)
    return response.data
  }),
)
