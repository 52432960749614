import {createAsyncThunk} from '@reduxjs/toolkit'
import {withToastForError} from '../../utils/redux/thunk'
import {
  deactivateAccount,
  getAccountActivity,
  getProfileData,
  updateProfile,
} from '../../api/profile'
import {
  ActivityParams,
  ActivityResponse,
  GetProfileResponse,
  ProfileType,
  UpdateProfileType,
} from '../../type/api/profile'
import {ApiResponse} from '../../type/api/auth'

export const getProfileDataAction = createAsyncThunk(
  'profile/getData',
  withToastForError<void, GetProfileResponse>(async () => {
    const response = await getProfileData()
    return response.data
  }),
)

export const updateProfileAction = createAsyncThunk(
  'profile/updateProfile',
  withToastForError<UpdateProfileType, ApiResponse>(async (body) => {
    const response = await updateProfile(body)
    return response.data
  }),
)

export const DeactivateAccountAction = createAsyncThunk(
  'profile/deactivateAccount',
  withToastForError<void, ApiResponse>(async () => {
    const response = await deactivateAccount()
    return response.data
  }),
)

export const getAccountActivityAction = createAsyncThunk(
  'profile/accountActivity',
  withToastForError<ActivityParams, ActivityResponse>(async (body) => {
    const response = await getAccountActivity(body)
    return response.data
  }),
)
