import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import AuthTemplate from '../../template/authTemplate/AuthTemplate'
import LoginForm from '../../organism/loginForm/LoginForm'
import {authInfoData} from '../../../utils/constants/constant'
import background from '../../../assets/images/background.png'
import {useAppDispatch, useSelector} from '../../../store'
import {loginUserAction} from '../../../store/action/auth'
import showNotification from '../../../function/notification'
import {RootState} from '../../../store/slices'
import {getProfileDataAction} from '../../../store/action/profile'
import {getCountryListAction} from '../../../store/action/general'

const LoginScreen = () => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<string | undefined>('')
  const [emailError, setEmailError] = useState<string | undefined>('')
  const token = useSelector((state: RootState) => state.auth.user?.token)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const countryList = useSelector((state: RootState) => state.general.countryList)
  const handleLogin = async (values: {email: string; password: string}) => {
    setErrors('')
    setLoading(true)
    const response: any = await dispatch(
      loginUserAction({
        email: values.email,
        password: values.password,
      }),
    )
    if (response?.payload?.data?.token) {
      showNotification({
        title: t('success'),
        type: 'success',
        message: `${t('loginSuccessFull')}`,
      })
      getProfileData()
      navigate('/dashboard')
    } else if (response?.payload?.data?.is_email_verified === false) {
      setEmailError(response?.payload?.data?.message)
      showNotification({
        title: t('error'),
        type: 'danger',
        message: `${response?.payload?.data?.message}`,
      })
    } else {
      showNotification({
        title: t('error'),
        type: 'danger',
        message: `${response?.payload?.data?.message}`,
      })
    }

    setLoading(false)
  }
  const getCountryList = async () => {
    if (countryList.length <= 0) {
      await dispatch(getCountryListAction()).unwrap()
    }
  }
  const getProfileData = async () => {
    try {
      await dispatch(getProfileDataAction())
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getCountryList()
  }, [])
  useEffect(() => {
    if (token) {
      navigate('/dashboard')
    }
  }, [])

  return (
    <AuthTemplate
      title={t('welcomeText')}
      description={t('loginToAccount')}
      FormComponent={
        <LoginForm
          handleLogin={(values: any) => handleLogin(values)}
          loading={loading}
          errorText={errors}
          setErrors={setErrors}
          emailError={emailError}
          setEmailError={setEmailError}
        />
      }
      authInfo={authInfoData}
      image={background}
      noBack
    />
  )
}

export default LoginScreen
